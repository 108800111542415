import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IKImage } from 'imagekitio-react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import '@fortawesome/fontawesome-free/css/all.css';

const Footer = () => {
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [footerParas, setFooterParas] = useState([]);
  const [footerLinks, setFooterLinks] = useState([]);
  const [error, setError] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const socialMediaResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_social_media');
        setSocialMediaLinks(socialMediaResponse.data);

        const footerParasResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_footer_paras');
        setFooterParas(footerParasResponse.data);

        const footerLinksResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_footer_links');
        setFooterLinks(footerLinksResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      }
    };

    fetchData();
  }, []);

  const handleEmailClick = () => {
    const mailLink = socialMediaLinks.find(link => link.category.toLowerCase() === 'mail');
 
      window.location.href = mailLink.link;
  };

  const handleLocationClick = () => {
    const footerLink = footerLinks.find(link => link.title.toLowerCase() === 'main office location');
    window.location.href = footerLink.link;
  };

  return (
    <footer className="text-center text-lg-start text-white" style={{ backgroundColor: '#000' }}>
      <div className="container p-4 pb-0">
        <section className="mb-4">
          <div className="row">
            
          {footerParas.map((para, index) => (
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              
                <div key={index}>
                  <h6 className="text-uppercase mb-4 font-weight-bold">
                    <img
                      src={para.logo}  // Assuming 'logo' field contains ImageKit path
                      
                      style={{ width: '200px', height: '200px', marginTop: '-50px' }}
                      className="logo"
                    />
                  </h6>
                  <p style={{ whiteSpace: 'pre-line' }}>{para.para}</p>
                </div>
             
            </div>
 ))}

 
            <hr className="w-100 clearfix d-md-none" />
            {footerLinks.map((footerLink, index) => (
  // Check if the heading is "Contact Info", if true, skip rendering
  footerLink.heading.toLowerCase() !== 'contact info' && (
    <div key={index} className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
      <h6 className="text-uppercase mb-4 font-weight-bold">{footerLink.heading}</h6>
      {footerLink.linkData.map((link, idx) => (
        <p key={idx}>
          <a href={link.link} className="text-white" target="_blank" rel="noopener noreferrer">
            {link.title}
          </a>
          <br className="w-100 clearfix d-md-none" />
        </p>
      ))}
    </div>
  )
))}

            
            <hr className="w-100 clearfix d-md-none" />
            {footerLinks.map((footerLink, index) => (
  // Check if the heading is "Contact Info", if true, skip rendering
  footerLink.heading.toLowerCase() == 'contact info' && (
    <div key={index} className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
      <h6 className="text-uppercase mb-4 font-weight-bold">{footerLink.heading}</h6>
      {footerLink.linkData.map((link, idx) => (
        <p key={idx}>
           {idx === 0 && ( // Show this for index 1
            <p onClick={handleLocationClick} style={{ cursor: 'pointer' }}>
              <i className="fas fa-home mr-3"></i>{link.title}
            </p>
          )}
           {idx === 1 && ( // Show this for index 1
             <p onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
             <i className="fas fa-envelope mr-3"></i> {link.title}
           </p>
          )}
           {idx === 2 && ( // Show this for index 1
             <p><i className="fas fa-phone mr-3"></i> {link.title}</p>
          )}
        </p>
        
      ))}
       <br className="w-100 clearfix d-md-none" />

    </div>
  )
))}
          </div>
        </section>
        <hr className="my-3" />
        <section className="p-3 pt-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8 text-center text-md-start">
              <div className="p-3">
                © 2024 Copyright: &nbsp;
                <a className="text-white" href="#">D1CAPITALPARKCITY</a>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
              {socialMediaLinks.map((link, index) => (
                <a key={index} href={link.link} className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
                  {link.category.toLowerCase() === 'mail' ? (
                    <i className="fas fa-envelope"></i>
                  ) : link.category.toLowerCase() === 'facebook' ? (
                    <i className="fab fa-facebook-f"></i>
                  ) : (
                    <i className={`fab fa-${link.category.toLowerCase()}`}></i>
                  )}
                </a>
              ))}
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
