import React, { useState, useEffect } from 'react';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../PannelStyling/AdminNews.css';
import '../../PannelStyling/AdminSalesPartnerStyling.css';
const AdminGallery = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    } else {
      // Simulated fetching data (replace with actual fetch from your backend)
      const fetchData = async () => {
        try {
          const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_gallery');
          if (!response.ok) {
            throw new Error('Failed to fetch gallery items');
          }
          const data = await response.json();
          setGalleryItems(data.reverse()); // Assuming data is an array of gallery items
        } catch (error) {
          console.error('Error fetching gallery data:', error);
          setError(true);
        }
      };

      fetchData();
    }
  }, [email]); // Fetch data when email changes

  const handleRemoveGalleryItem = async (id) => {
    // Ask for confirmation before deleting the gallery item
    const confirmDelete = window.confirm('Are you sure you want to delete this gallery item?');
    if (!confirmDelete) {
      return; // If user cancels, do nothing
    }

    try {
      // Send DELETE request to delete the gallery item
      await fetch(`https://d1server.d1capitalparkcity.com/delete_gallery/${id}`, {
        method: 'DELETE',
      });

      // After successful removal, fetch and update the gallery items
      const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_gallery');
      const data = await response.json();
      setGalleryItems(data);
    } catch (error) {
      console.error('Error removing gallery item:', error);
      alert(error.response.data.error); 
    }
  };

  const handleAddNewGalleryItem = () => {
    navigate('/addgallery', { state: { email, userRole, userPermissions } });
  };

  const handleEditGalleryItem = (id) => {
    navigate('/editgallery', { state: { id, email, userRole, userPermissions } });
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Gallery Items</h1>
            <p style={{ color: 'red' }}><b>Note: Image Should be uploaded at Image kit and size should be (1920x1080)</b></p>

            <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddNewGalleryItem}>Add New Gallery Item</button>
            </div>
            <div className="partners-grid">
              {galleryItems.map((item, index) => (
                <div key={index} className="partner-card">
                  <img src={item.link} alt={item.title} className="partner-logo" />
                  <h2>{item.title}</h2>
                  <p>{item.index}</p>
                  <p>{item.description}</p>
                  <div className='News-Buttons'>
                    <button className="btn42-43 btn-42" onClick={() => handleEditGalleryItem(item._id)}>Edit</button>
                    <button className="btn42-43 btn-42" onClick={() => handleRemoveGalleryItem(item._id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminGallery;
