import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddNewsForm = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    imageSrc: '',
    heading: '',
    details: [{ title: '', content: '' }],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDetailsChange = (index, e) => {
    const { name, value } = e.target;
    const newDetails = [...formData.details];
    newDetails[index][name] = value;
    setFormData({ ...formData, details: newDetails });
  };

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]); // Fetch data when email changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_news_events', JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('News/Event added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminnews', { state: { email, userRole, userPermissions } });
      // Redirecting or storing the id for further operations
      // window.location.href = `/news-details/${response.data.id}`;
    } catch (error) {
      console.error('Error adding News/Event:', error.response.data);
      alert('Sorry for the Inconvenance, Technical Issue Occurred.');
    }
  };
  
  

  return (
    <div className='AddNews-Container'>

    <Header />
      {error ? (
         <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>

        ) : (
    
    <div>
      <div>
      <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
      </div>
      <h1 className="h1stake" >Add New Event</h1>
      <div className='AddNews-Form'>
       
    <form onSubmit={handleSubmit}>
      <label>Image:</label>
      <input
        type="text"
        name="imageSrc"
        value={formData.imageSrc}
        onChange={handleChange}
        placeholder="Image Source URL"
        required
      />
      <label>Heading:</label>
      <input
        type="text"
        name="heading"
        value={formData.heading}
        onChange={handleChange}
        placeholder="Heading"
        required
      />
       <label>Sub-Headings & Detail:</label>
      {formData.details.map((detail, index) => (
        <div className='AddNews-Detial' key={index}>
         
          <input
            type="text"
            name="title"
            value={detail.title}
            onChange={(e) => handleDetailsChange(index, e)}
            placeholder="Detail Title"
            required
          />
          <textarea
            name="content"
            value={detail.content}
            onChange={(e) => handleDetailsChange(index, e)}
            placeholder="Detail Content"
            required
          />
        </div>
      ))}
      <button type="button" onClick={() => setFormData({ ...formData, details: [...formData.details, { title: '', content: '' }] })}>
        Add Detail
      </button>
      <button type="submit">Submit</button>
    </form>
    </div>
    </div>
     )}
    </div>
  );
};

export default AddNewsForm;
