import React, { useState, useEffect } from 'react';
import '../NewsEvents/NewsPopup.css';
import axios from 'axios';

const DevelopmentPopUp = ({ onClose }) => {
const [popups, setPopups] = useState([]);

  useEffect(() => {
    // Fetch pop-ups from the API
    const fetchPopups = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_popups?type=DevelopmentUpdate'); // Fetch only popups with type 'News'
        console.log(response.data);
        setPopups(response.data.reverse());
      } catch (error) {
        console.error('Error fetching pop-ups:', error);
        alert('Technical Issue Occurred While Fetching Pop data.');
      }
    };

    fetchPopups();
  }, []);

  return (
    <div className="newspopup-container">
      <button className="newsclose-button" onClick={onClose}>X</button> {/* Close button */}
      {popups.map((popup, index) => (
        <div key={index} className="newspopup-content">
         <img src={popup.imageSrc} alt=''/>
          <h1><b>{popup.title}</b></h1>
          <h2 style={{ fontSize: '24px' }}>{popup.heading}</h2>
          {popup.details.map((detail, detailIndex) => (
            <div key={detailIndex}>
              <p style={{ whiteSpace: 'pre-line', fontSize: '20px'  }}><strong>{detail.title}</strong> </p>
              <p style={{ whiteSpace: 'pre-line'  }} className='newsred'>{detail.content}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DevelopmentPopUp;
