import React, { useState, useEffect } from 'react';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../PannelStyling/AdminNews.css';

const AdminPricing = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [pricePlans, setPricePlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_price_plans');
        if (!response.ok) {
          throw new Error('Sorry, a technical issue occurred while fetching price plans.');
        }
        const data = await response.json();
        setPricePlans(data.reverse());
      } catch (error) {
        console.error('Error fetching price plans:', error);
        setError(true);
      }
    };

    fetchData();
  }, [email]);

  const handleAddPricePlan = () => {
    navigate('/addpriceplan', { state: { email, userRole, userPermissions } });
  };

  const handleRemovePricePlan = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Partner?');
    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(`https://d1server.d1capitalparkcity.com/delete_price_plan/${id}`, { method: 'DELETE' });
      const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_price_plans');
      const data = await response.json();
      setPricePlans(data.reverse());
    } catch (error) {
      console.error('Error removing article:', error);
      throw new Error('Sorry for the Inconvenience, Technical Issue Occurred!');
    }
  };

  const handleEditPricePlan = (id) => {
    navigate('/editpriceplan', { state: { id, email, userRole, userPermissions }});
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Pricing</h1>
            <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddPricePlan}>Add New Price Plan</button>
            </div>
            <div className="adminnews-events-grid">
              {pricePlans.map((pricePlan, index) => (
                <div key={index} className="news-event-card">
                  {pricePlan.block && <h1>{pricePlan.block}</h1>}
                  {pricePlan.category && <h2>{pricePlan.category}</h2>}
                  {pricePlan.costOfLand !== null && pricePlan.costOfLand !== undefined && (
                    <p>Cost of Land: {pricePlan.costOfLand}</p>
                  )}
                  {pricePlan.downPayment !== null && pricePlan.downPayment !== undefined && (
                    <p>Down Payment: {pricePlan.downPayment}</p>
                  )}
                  {pricePlan.monthlyInstallments !== null && pricePlan.monthlyInstallments !== undefined && (
                    <p>Monthly Installments: {pricePlan.monthlyInstallments}</p>
                  )}
                  {pricePlan.confirmation !== null && pricePlan.confirmation !== undefined && (
                    <p>Confirmation: {pricePlan.confirmation}</p>
                  )}
                  {pricePlan.biAnnualInstallments !== null && pricePlan.biAnnualInstallments !== undefined && (
                    <p>Bi-Annual: {pricePlan.biAnnualInstallments}</p>
                  )}
                  {pricePlan.annualInstallments !== null && pricePlan.annualInstallments !== undefined && (
                    <p>Annual: {pricePlan.annualInstallments}</p>
                  )}
                  {pricePlan.uponBallot !== null && pricePlan.uponBallot !== undefined && (
                    <p>Upon Ballot: {pricePlan.uponBallot}</p>
                  )}
                  {pricePlan.uponPossession !== null && pricePlan.uponPossession !== undefined && (
                    <p>Upon Possession: {pricePlan.uponPossession}</p>
                  )}
                  {pricePlan.developmentcharges !== null && pricePlan.developmentcharges !== undefined && (
                    <p>Development Charges: {pricePlan.developmentcharges}</p>
                  )}
                  {pricePlan.miscellaneous !== null && pricePlan.miscellaneous !== undefined && (
                    <p>Miscellaneous: {pricePlan.miscellaneous}</p>
                  )}
                  <div className='News-Buttons'>
                    <button className="btn42-43 btn-42" onClick={() => handleEditPricePlan(pricePlan._id)}>Edit</button>
                    <button className="btn42-43 btn-42" onClick={() => handleRemovePricePlan(pricePlan._id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPricing;
