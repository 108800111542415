import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/AdminHeader.js';
import Sidebar from '../components/AdminSidebar.js';
import profilepic from '../../../assets/mamsaliha.png'
import '../PannelStyling/Profile.css'; // Import CSS file for styling

const Profile = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [errormsg, setErrormsg] = useState(null);

  useEffect(() => {

    if (!email) {
      setErrormsg('Error 404: Page Not Found');
      return;
    }
  
    fetchProfileData(email);
  }, [location.search]);

  const fetchProfileData = async (email) => {
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/fetchadminprofile', { email });
      if (response.data.error) {
        setErrormsg(response.data.error);
        alert(response.data.error);
      } else {
        setProfileData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrormsg(error.response.data.error);
        alert(error.response.data.error);
      } else {
        alert("Sorry for the inconvenience! Technical issues occurred. Please try again later!");
        console.log(error);
      }
    }
  };

const getRoleString = (role) => {
  switch (role) {
    case 1:
      return 'Super Admin';
    case 2:
      return 'Admin';
    case 3:
      return 'Moderator';
    case 4:
      return 'Content Editor';
    case 5:
      return 'Customer Support';
    default:
      return 'Not Assigned';
  }
};


const handleEditProfile = () => {
  navigate('/edit-profile', { state: { email, userRole, userPermissions } }); // Navigate to edit-profile with email state
};

  return (
    <div>
      <Header />
      <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
      <div className="profile-container">
        <h1>My Profile</h1>
        {errormsg ? (
          <p>{errormsg}</p>
        ) : profileData ? (
          <div >
            <div className="profile-heading">Personal Information</div>
            <div className="profile-item">
              <span className="label">Name:</span>
              <span className="value">{profileData.name}</span>
            </div>
            <div className="profile-item">
              <span className="label">Role:</span>
              <span className="value">{profileData ? getRoleString(profileData.role) : 'Loading...'}</span>
            </div>
            <div className="profile-heading">Contact Information</div>
            <div className="profile-item">
              <span className="label">Email:</span>
              <span className="value">{profileData.email}</span>
            </div>
            <div className="profile-item">
              <span className="label">Phone Number:</span>
              <span className="value">{profileData.pnumber}</span>
            </div>
          </div>
        ) : (
          <p>Loading profile...</p>
        )}
         <button className='btn42-43 btn-42' onClick={handleEditProfile} style={{ textDecoration: 'none', color: 'inherit' }}>
          Edit Profile
        </button>
      </div>
    </div>
  );
};

export default Profile;
