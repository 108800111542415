import React, { useState, useEffect } from 'react';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../PannelStyling/AdminNews.css'; // Adjust import as per your styling structure
import '../../PannelStyling/AdminSalesPartnerStyling.css'; // Adjust import as per your styling structure

const ProjectAmenitiesAdmin = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true);
      return;
    } else {
      // Fetch amenities data
      const fetchAmenities = async () => {
        try {
          const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_project_amenities'); // Adjust endpoint as per your backend
          if (!response.ok) {
            throw new Error('Failed to fetch amenities');
          }
          const data = await response.json();
          setAmenities(data.reverse()); // Reverse data if needed
        } catch (error) {
          console.error('Error fetching amenities:', error);
          setError(true);
        }
      };

      fetchAmenities();
    }
  }, [email]);

  const handleRemoveAmenity = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Amenity?');
    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(`https://d1server.d1capitalparkcity.com/delete_project_amenity/${id}`, {
        method: 'DELETE',
      });

      const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_project_amenities');
      const data = await response.json();
      setAmenities(data.reverse());
    } catch (error) {
      console.error('Error removing amenity:', error);
      throw new Error('Failed to delete amenity');
    }
  };

  const handleAddNewAmenity = () => {
    navigate('/addeditprojectamenities', { state: { email, userRole, userPermissions } });
  };

  const handleEditAmenity = (id) => {
    navigate('/addeditprojectamenities', { state: { id, email, userRole, userPermissions } });
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Project Amenities</h1>
            <p style={{ color: 'red' }}><b>Note: Upload Image using Image kit, Size should be (1920x1080)</b></p>

            <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddNewAmenity}>Add New Amenity</button>
            </div>
            <div className="partners-grid">
              {amenities.map((amenity, index) => (
                <div key={index} className="partner-card">
                  <img src={amenity.image} alt={amenity.title} className="partner-logo" />
                  <h2>{amenity.title}</h2>
                  <p>{amenity.description}</p>
                  <div className='News-Buttons'>
                    <button className="btn42-43 btn-42" onClick={() => handleEditAmenity(amenity._id)}>Edit</button>
                    <button className="btn42-43 btn-42" onClick={() => handleRemoveAmenity(amenity._id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectAmenitiesAdmin;
