import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/AdminHeader.js';
import Sidebar from '../components/AdminSidebar.js';
import profilepic from '../../../assets/mamsaliha.png'
import '../PannelStyling/EditProfile.css'; // Import CSS file for styling


function SuccessMessage({ message }) {
  return (
    <div class="alert alert-success" role="alert">
      {message}
    </div>
  );
}


const EditProfile = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [profileData, setProfileData] = useState(null);
  const [editedProfileData, setEditedProfileData] = useState(null); // Define editedProfileData state
  const [photoFile, setPhotoFile] = useState(null); // Define photoFile state
  const [errormsg, setErrormsg] = useState(null);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');


  useEffect(() => {

    if (!email) {
      setErrormsg('Error 404: Page Not Found');
      return;
    }
  
    fetchProfileData(email);
  }, [location.search]);

  const fetchProfileData = async (email) => {
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/fetchadminprofile', { email });
      if (response.data.error) {
        setErrormsg(response.data.error);
        alert(response.data.error);
      } else {
        setProfileData(response.data);
        // Initialize edited profile data with the fetched profile data
        setEditedProfileData(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrormsg(error.response.data.error);
        alert(error.response.data.error);
      } else {
        alert("Sorry for the inconvenience! Technical issues occurred. Please try again later!");
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfileData({ ...editedProfileData, [name]: value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Include the admin ID in the edited profile data
      const dataToUpdate = { ...editedProfileData, _id: profileData._id };
  
      const response = await axios.post('https://d1server.d1capitalparkcity.com/updateadmin', dataToUpdate);
      if (response.data.error) {
        setErrormsg(response.data.error);
        alert(response.data.error);
      } else {
        // Reload profile data after successful update
        fetchProfileData(email);
        setSuccessMessage('Profile updated successfully!');
        setTimeout(() => {
          setSuccessMessage('');
          navigate('/profile', { state: { email } });
        }, 2000); // 3000 milliseconds (3 seconds) delay before navigation
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrormsg(error.response.data.error);
        alert(error.response.data.error);
      } else {
        alert("Sorry for the inconvenience! Technical issues occurred. Please try again later!");
        console.log(error);
      }
    }
  };
  

  const getRoleString = (role) => {
    switch (role) {
      case 1:
        return 'Super Admin';
      case 2:
        return 'Admin';
      case 3:
        return 'Moderator';
      case 4:
        return 'Content Editor';
      case 5:
        return 'Customer Support';
      default:
        return 'Not Assigned';
    }
  };


  return (
    <div>
      <Header />
      <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
      <div className="edit-profile-container">
        <h1>My Profile</h1>
        {successMessage && <SuccessMessage message={successMessage} />}
        {profileData ? (
          <form onSubmit={handleSubmit} enctype="multipart/form-data">
            <div>
               {/* <div className="edit-profile-heading">Profile Photo</div>
            <div className="edit-main-profile-photo">
                <div className="edit-profile-photo1">
                  <img
                    src={profileData.photoUrl || '/path/to/default/profile/photo.png'}
                    alt="Profile"
                    className="edit-profile-photo"
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop in case default image also fails
                      e.target.src = profilepic;
                    }}
                  />
            
                </div>
              </div>
              <div className='edit-profile-photo-borwser'>
                  <input type="file"  className="edit-profile-photo-input"  onChange={handlePhotoUpload} />
                  </div>*/}

              <div className="edit-profile-heading">Personal Information</div>
              <div className="edit-profile-item">
                <span className="edit-label">Name:</span>
                <input
                  type="text"
                  name="name"
                  value={editedProfileData.name || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className="edit-profile-item">
                <span className="edit-label">Role:</span>
                <input
                  type="text"
                  name="role"
                  value={getRoleString(editedProfileData.role) || ''}
                  readOnly
                />
              </div>
              <div className="edit-profile-heading">Contact Information</div>
              <div className="edit-profile-item">
                <span className="edit-label">Email:</span>
                <input
                  type="email"
                  name="email"
                  value={editedProfileData.email || ''}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              <div className="edit-profile-item">
                <span className="edit-label">Phone Number:</span>
                <input
                  type="tel"
                  name="pnumber"
                  value={editedProfileData.pnumber || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button className='btn42-43 btn-42' type="submit">Save Changes</button>
          </form>
        ) : (
          <p>Loading profile...</p>
        )}

      </div>
    </div>
  );
};

export default EditProfile;
