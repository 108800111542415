import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/AdminHeader.js';
import Sidebar from '../components/AdminSidebar.js';
import AdminList from '../Dashboard/AdminListData.js';
import TotalBlogs from '../Dashboard/CustomerTotalBlog.js';
import ContactUsDashboard from '../Dashboard/ContacUsDashboard.js';
import axios from 'axios'; // Import Axios
import '../PannelStyling/Dashboard.css';
import PageViewCounter from '../Dashboard/PageView.js';

const CustomerDashboard = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!email) {
      setError(true);
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch user profile
        const profileResponse = await axios.post('https://d1server.d1capitalparkcity.com/fetchadminprofile', { email }, {
          headers: { 'Content-Type': 'application/json' }
        });
        console.log('Profile data:', profileResponse.data);
        setUserData(profileResponse.data);
        setUserRole(profileResponse.data.role);

        // Fetch user permissions
        const permissionsResponse = await axios.get(`https://d1server.d1capitalparkcity.com/fetch-permissions/${profileResponse.data.role}`);
        console.log('Permissions data:', permissionsResponse.data);
        setUserPermissions(permissionsResponse.data.permissions);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [email]);


  return (
    <div>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <>
      <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
      <div className='Dashboard-Container'>
            {userData && (
              <h2 className="typewriter">Welcome Back! <br/>{userData.name}</h2>
            )}
            <div>
              <TotalBlogs email={email} userRole={userRole} userPermissions={userPermissions} />
            </div>
              
              <div>
                <ContactUsDashboard email={email} />
                </div>
            
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDashboard;
