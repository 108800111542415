import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../PannelStyling/AdminNews.css';
import ReactLoading from '../../../../assets/React-Loading.gif';
import { Helmet } from 'react-helmet-async';
import Header from '../../components/AdminHeader.js';
import Sidebar from '../../components/AdminSidebar.js';

const AdminNews = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [newsEvents, setNewsEvents] = useState([]);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
        } else {
            const fetchNewsEvents = async () => {
                try {
                    const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_news_events');
                    const data = await response.json();
                    setNewsEvents(data.reverse()); // Reverse the order of the news/events
                } catch (error) {
                    console.error('Error fetching news events:', error);
                    setError(true);
                }
            };

            fetchNewsEvents();
        }
    }, [email]);

    const handleRemoveArticle = async (id) => {
        // Ask for confirmation before deleting the article
        const confirmDelete = window.confirm('Are you sure you want to delete this article?');
        if (!confirmDelete) {
            return; // If user cancels, do nothing
        }
    
        try {
            // Send DELETE request to delete the article
            await fetch(`https://d1server.d1capitalparkcity.com/delete_news/${id}`, {
                method: 'DELETE',
            });
    
            // After successful removal, fetch and update the news events
            const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_news_events');
            const data = await response.json();
            setNewsEvents(data.reverse());
        } catch (error) {
            console.error('Error removing article:', error);
        }
    };
    
    if (error) {
        return (
            <>
                <div className='AdminNews-Header'> <Header /></div>
                <h1 style={{ textAlign: 'center', marginTop: '20vh' }}>Error 404: Page Not Found!</h1>
            </>
        );
    }

    const handleAddNewArticle = () => {
        navigate('/addnews', { state: { email, userRole, userPermissions} });
    };

    const handleEditNews = (id) => {
        navigate('/editnews', { state: { id, email, userRole, userPermissions } });
    };

    return (
        <div className='AdminNews-Container'>
            <div className='AdminNews-Header'>
                <Header />
            </div>
            <div className='AdminContent'>
                <div className='AdminNews-Sidebar'>
                <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
                </div>
                <div className='AdminNews-Main'>
                    <Helmet>
                        <title>D1 Capital Park City | News/Events</title>
                        <meta name="description" content="D1 Capital Park City Latest News and Events Updates." />
                        <link rel='canonical' href='https://d1capitalparkcity.com/newsevent' />
                    </Helmet>
                    <Suspense fallback={<div style={{ backgroundColor: "#000", width: "100%", height: "100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
                        <div>
                            <h1 className="h1stake">LATEST NEWS/EVENTS</h1>
                            <p style={{ color: 'red' }}><b>Note: Image Should be uploaded at Image kit and size should be (540x540)</b></p>

                        </div>

                        <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddNewArticle}>Add New Article</button>
                        </div>

                        <div className="adminnews-events-grid">
                            {newsEvents.map((event, index) => (
                                <div key={index} className="news-event-card">
                                    <img src={event.imageSrc} alt={event.heading} className="news-event-image" loading='lazy' />
                                    <h2 className="news-event-heading">{event.heading}</h2>
                                    <div className='News-Buttons'>
                                    <button className="btn42-43 btn-42" onClick={() => handleEditNews(event._id)}>Edit Article</button>
                                    <button className="btn42-43 btn-42" onClick={() => handleRemoveArticle(event._id)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default AdminNews;
