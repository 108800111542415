import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';

const AdminFooterLinks = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [footerLinks, setFooterLinks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
            return;
        }
        // Fetch footer links from the API
        const fetchFooterLinks = async () => {
            try {
                const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_footer_links');
                console.log(response.data);
                setFooterLinks(response.data.reverse());
            } catch (error) {
                console.error('Error fetching footer links:', error);
                setError(true); // Set error to true if there's an error fetching footer links
            }
        };

        fetchFooterLinks();

    }, []);

    const handleRemoveFooterLink = async (id) => {
        // Ask for confirmation before deleting the footer link
        const confirmDelete = window.confirm('Are you sure you want to delete this footer link?');
        if (!confirmDelete) {
            return; // If user cancels, do nothing
        }

        try {
            // Send DELETE request to delete the footer link
            await axios.delete(`https://d1server.d1capitalparkcity.com/delete_footer_link/${id}`);

            // After successful removal, fetch and update the footer links
            const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_footer_links');
            setFooterLinks(response.data.reverse());
        } catch (error) {
            console.error('Error removing footer link:', error);
        }
    };

    const handleEditFooterLink = (id) => {
        navigate('/editfooterlink', { state: { id, email, userRole, userPermissions } });
    };

    const handleAddFooterLink = () => {
        navigate('/editfooterlink', { state: { email, userRole, userPermissions } });
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                       email={email || ''} 
                       userRole={userRole || ''} 
                       userPermissions={userPermissions || []} 
                     /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">Footer Links</h1>
                        <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddFooterLink}>Add New Footer Link</button>
                        </div>
                        <div className="adminnews-events-grid">
                            {footerLinks.map((link, index) => (
                                <div key={index} className='news-event-card'>
                                    <h2>{link.heading}</h2>
                                    <div className='contact-details'>
                                        {link.linkData.map((data, idx) => (
                                            <p key={idx}>
                                                <a href={data.link} target='_blank' rel='noopener noreferrer'>{data.title}</a>
                                            </p>
                                        ))}
                                    </div>
                                    <div className='News-Buttons'>
                                        <button className="btn42-43 btn-42" onClick={() => handleEditFooterLink(link._id)}>Edit</button>
                                        <button className="btn42-43 btn-42" onClick={() => handleRemoveFooterLink(link._id)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                      
                                    
                              
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminFooterLinks;
