import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';

const ContactDetailAdmin = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [contactDetails, setContactDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
            return;
        }

        // Fetch contact details from the API
        const fetchContactDetails = async () => {
            try {
                const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_contact_details');
                console.log(response.data);
                setContactDetails(response.data.reverse());
            } catch (error) {
                console.error('Error fetching contact details:', error);
                setError(true); // Set error to true if there's an error fetching contact details
            }
        };

        fetchContactDetails();

    }, [email]);

    const handleRemoveContactDetail = async (id) => {
        // Ask for confirmation before deleting the contact detail
        const confirmDelete = window.confirm('Are you sure you want to delete this contact detail?');
        if (!confirmDelete) {
            return; // If user cancels, do nothing
        }

        try {
            // Send DELETE request to delete the contact detail
            await axios.delete(`https://d1server.d1capitalparkcity.com/delete_contact_detail/${id}`);

            // After successful removal, fetch and update the contact details
            const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_contact_details');
            setContactDetails(response.data.reverse());
        } catch (error) {
            console.error('Error removing contact detail:', error);
        }
    };

 

    const handleEditContactDetail = (id) => {
        navigate('/editcontactdetail', { state: {id, email, userRole, userPermissions} });
    };
    const handleAddContactDetail = () => {
        navigate('/editcontactdetail', { state: { email, userRole, userPermissions } });
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                      email={email || ''} 
                      userRole={userRole || ''} 
                      userPermissions={userPermissions || []} 
                    /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">Contact Details</h1>
                        <div className='AddNewEventButton'>
                        <button className="btn42-43 btn-42"  onClick={handleAddContactDetail}>Add New Contact Details</button>
                        </div>
                        <div className="adminnews-events-grid">
                            {contactDetails.map((detail, index) => (
                                <div key={index} className="news-event-card">
                                    <h2>{detail.heading}</h2>
                                    <div className="contact-details">
                                        <p style={{ whiteSpace: 'pre-line' }}>{detail.details}</p>
                                    </div>
                                    <div className='News-Buttons'>
                                        <button className="btn42-43 btn-42" onClick={() => handleEditContactDetail(detail._id)}>Edit</button>
                                        <button className="btn42-43 btn-42" onClick={() => handleRemoveContactDetail(detail._id)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ContactDetailAdmin;
