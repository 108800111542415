import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddEditLocationMap = () => {
  const location = useLocation();
  const { id, email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [profileImage, setProfileImage] = useState(null); // Use null for file upload
  const [link, setLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true);
    } else if (id) {
      fetchUnderstandingLocation(id);
    }
  }, [id, email]);

  const fetchUnderstandingLocation = async (id) => {
    try {
      const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_understandinglocation/${id}`);
      const { profile, link } = response.data;
      setProfileImage(profile.data); // Assuming profile image data is retrieved as base64 string
      setLink(link);
    } catch (error) {
      console.error('Error fetching understanding location:', error);
      setError(true);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file); // Set the file object to state
  };

  const handleEditUnderstandingLocation = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('profile', profileImage); // Append file to FormData
      formDataToSend.append('link', link); // Append other fields as needed

      if (id) {
        await axios.put(`https://d1server.d1capitalparkcity.com/update_understandinglocation/${id}`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data' // Set content type for file upload
          }
        });
        alert("Understanding Location Updated Successfully");
      } else {
        await axios.post(`https://d1server.d1capitalparkcity.com/save_understandinglocation`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data' // Set content type for file upload
          }
        });
        alert("Understanding Location Added Successfully");
      }
      
      navigate('/adminunderstandinglocation', { state: { email, userRole, userPermissions } }); // Redirect after successful update
    } catch (error) {
      console.error('Error updating understanding location:', error);
      // Handle error as needed
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">{id ? 'Edit Understanding Location' : 'Add New Understanding Location'}</h1>
          <div className='AddNews-Form'>
            <form onSubmit={(e) => e.preventDefault()} encType="multipart/form-data">
              <div className='form-group'>
                <label htmlFor='profileImage'>Image</label>
                <input
                  type='file'
                  id='profileImage'
                  onChange={handleFileChange} // Handle file change
                  required={!id} // Required if adding a new location
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <label htmlFor='link'>Link</label>
                <input
                  type='text'
                  id='link'
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  required
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <button onClick={handleEditUnderstandingLocation}>
                  {id ? 'Update Understanding Location' : 'Add Understanding Location'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEditLocationMap;
