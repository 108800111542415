import React, { useState, useEffect } from 'react';
import './Popup.css';
import axios from 'axios';
import Button from '../../utils/Button';

const Popup = ({ onClose }) => {

  const [popups, setPopups] = useState([]);

  useEffect(() => {
    // Fetch pop-ups from the API
    const fetchPopups = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_popups?type=Welcome'); // Fetch only popups with type 'News'
        console.log(response.data);
        setPopups(response.data.reverse());
      } catch (error) {
        console.error('Error fetching pop-ups:', error);
       // alert('Technical Issue Occurred While Fetching Pop data.');
      }
    };

    fetchPopups();
  }, []);

  return (
    <div className="popup-container">
      <button className="close-button" onClick={onClose}>X</button> {/* Close button */}
      {popups.map((popup, index) => (
      <div  key={index} className="popup-content">
        <h1>{popup.title}</h1>
        <h3>{popup.heading} </h3>
        {popup.details.map((detail, detailIndex) => (
            <div key={detailIndex}>
          
    <p className='red' style={{ whiteSpace: 'pre-line'}}><b>{detail.title}</b>  </p>
    
    <div className='notification' style={{ whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{ __html: detail.content }} />
    <hr/> 

</div>
          ))}
          <Button/>
      </div>
      ))}
    </div>
  );
};

export default Popup;
