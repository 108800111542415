import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Import CSS for styling

const FooterParaEdit = () => {
    const location = useLocation();
    const { id, email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [logo, setLogo] = useState('');
    const [para, setPara] = useState('');
    const navigate = useNavigate();


        useEffect(() => {
            if (!email) {
                setError(true);
                return;
            }
            else if(id) {
                fetchFooterPara(id);
            }
        }, [id]);


    const fetchFooterPara = async (id) => {
        try {
            const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_footer_para/${id}`);
            const { logo, para } = response.data;
            setLogo(logo);
            setPara(para);
        } catch (error) {
            console.error('Error fetching footer para:', error);
            setError(true);
        }
    };

    const handleAddFooterPara = async () => {
        try {
            const newFooterPara = { logo, para };
            if (id) {
                await axios.put(`https://d1server.d1capitalparkcity.com/update_footer_para/${id}`, newFooterPara);
                alert("Footer Para Updated Successfully");
            } else {
                await axios.post('https://d1server.d1capitalparkcity.com/save_footer_para', newFooterPara);
                alert("Footer Para Added Successfully");
            }
            navigate('/adminfooterpara', { state: { email, userRole, userPermissions } }); // Redirect after successful addition or update
        } catch (error) {
            console.error('Error adding/updating footer para:', error);
            // Handle error as needed
        }
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                       email={email || ''} 
                       userRole={userRole || ''} 
                       userPermissions={userPermissions || []} 
                     />                     </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">{id ? 'Edit Footer Paragraph' : 'Add New Footer Paragraph'}</h1>
                        <div className='AddNews-Form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <label htmlFor='logo'>Logo</label>
                                    <input
                                        type='text'
                                        id='logo'
                                        value={logo}
                                        onChange={(e) => setLogo(e.target.value)}
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='para'>Paragraph</label>
                                    <textarea
                                        id='para'
                                        value={para}
                                        onChange={(e) => setPara(e.target.value)}
                                        rows='4'
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <button onClick={handleAddFooterPara} >
                                        {id ? 'Update Footer Paragraph' : 'Add Footer Paragraph'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FooterParaEdit;
