import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios'; // Import Axios

const AdminContactusdata = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [contactData, setContactData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true); // Set error to true if email is not provided
            return;
        }
        // Fetch data when component mounts
        fetchData();
    }, [email]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_contact_submissions'); // Use axios to make GET request
            if (response.status === 200) {
                const data = response.data; // Extract data from response
                setContactData(data);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(true);
        }
    };

    const handleDelete = async (id) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this Contact?');
      if (!confirmDelete) {
          return; // If user cancels, do nothing
      }
  
      try {
          await axios.delete(`https://d1server.d1capitalparkcity.com/delete_contact_submission/${id}`);
          // If deletion is successful, fetch updated data
          fetchData();
      } catch (error) {
          console.error('Error deleting contact submission:', error);
          // Handle error
      }
  };

    const handleEdit = (id) => {
      navigate('/editcontactus', { state: { id, email, userRole, userPermissions } });
  };
    return (
        <div className='AdminNews-Container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent' style={{ display: 'flex', maxWidth: '1200px', margin: '20px auto' }}>
                    <div className='AdminNews-Sidebar' style={{ flex: '1' }}>
                    <Sidebar 
                      email={email || ''} 
                      userRole={userRole || ''} 
                      userPermissions={userPermissions || []} 
                    />           
                    </div>
                    <div className='AdminNews-Main' style={{ flex: '3', paddingLeft: '20px' }}>
                        <h1 className="h1stake">Contact Us Leads</h1>
                        <div className="table-styling">
                            <table className="table table-striped" style={{ width: '120%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Name</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '10%' }}>Email</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '10%' }}>Phone Number</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '30%' }}>Message</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Time</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Remarks</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '10%' }}>Contacted By</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '10%' }}>Update/Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contactData.map(contact => (
                                        <tr key={contact._id}>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{contact.Name}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{contact.Email}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{contact.PhoneNumber}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{contact.Message}</td>
                                            <th style={{ border: '1px solid black', padding: '8px' }}>{contact.Time}</th>
                                            <td style={{ border: '1px solid black', padding: '8px' }}> {contact.Reamarks}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}> {contact.Contactedby}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}> 
                                            <div style={{padding: '8px'}}>
                                              <td>
                                                <button style={{marginRight:'10px'}} className="btn btn-primary" onClick={() => handleEdit(contact._id)}>Edit</button>
                                                </td>
                                                <td>
                                                <button className="btn btn-danger" onClick={() => handleDelete(contact._id)}>Delete</button>
                                                </td>
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdminContactusdata;
