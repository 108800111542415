import React, { useState, useEffect } from 'react';
import './TotalBlogs.css'; // Import the external CSS file
import axios from 'axios';
import { FaUsers, FaHandshake, FaNewspaper, FaImage, FaVideo } from 'react-icons/fa'; // Import Font Awesome icons
import { Audio } from "react-loader-spinner";
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate hook from React Router



const TotalBlogs = ({ email, userRole, userPermissions }) => {
    const [partnerCount, setPartnerCount] = useState(0);
    const [memberCount, setMemberCount] = useState(0);
    const [newsEventsCount, setNewsEventsCount] = useState(0);
    const [developmentUpdatesImgCount, setDevelopmentUpdatesImgCount] = useState(0);
    const [developmentUpdatesVideoCount, setDevelopmentUpdatesVideoCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch partner count
                const partnerResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_partner');
                setPartnerCount(partnerResponse.data.length);

                const memberResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_aboutus');
                setMemberCount(memberResponse.data.length);

                // Fetch news and events count
                const newsEventsResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_news_events');
                setNewsEventsCount(newsEventsResponse.data.length);

                // Fetch development updates data to count images and videos separately
                const developmentUpdatesResponse = await axios.get('https://d1server.d1capitalparkcity.com/fetch_development_updates');
                let imgCount = 0;
                let videoCount = 0;
                developmentUpdatesResponse.data.forEach(item => {
                    if (item.type === 'image') {
                        imgCount++;
                    } else if (item.type === 'video') {
                        videoCount++;
                    }
                });
                setDevelopmentUpdatesImgCount(imgCount);
                setDevelopmentUpdatesVideoCount(videoCount);

                setLoading(false); // Set loading to false when all counts are fetched
            } catch (error) {
                setError("Sorry for the inconvenience! Technical issues occurred. Please try again later.");
                console.error('Error fetching data:', error);
                setLoading(false); // Set loading to false on error
            }
        };

        fetchData();
    }, []); // Fetch data when component mounts



    const handleNavigate = (routePath) => {
        navigate(routePath, { state: { email, userRole, userPermissions } });
    };

    return (
        <div className="Total-Container">
    
            {loading ? (
                   <div className="loader">
                   <Audio color="#FFD700" height={80} width={80} />
               </div>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="Total-Boxes">

                    <div className="Total-Box1" onClick={() => handleNavigate('/AdminAboutus')}>
                    <FaUsers className="icon" />
                        <h2>Team Members</h2>
                        <p>{memberCount}</p>
                    </div>

                    <div className="Total-Box2" onClick={() => handleNavigate('/adminsalespartner')}>
                    <FaHandshake className="icon" />
                        <h2>Sales Partner</h2>
                        <p>{partnerCount}</p>
                    </div>

                    <div className="Total-Box3"  onClick={() => handleNavigate('/adminnews')}>
                    <FaNewspaper className="icon" />
                        <h2>News and Events </h2>
                        <p>{newsEventsCount}</p>
                    </div>

                    <div className="Total-Box4"  onClick={() => handleNavigate('/admindevelopment')}>
                    <FaImage className="icon" />
                        <h2>Development Updates Image </h2>
                     <p>{developmentUpdatesImgCount}</p>
                    </div>
                    <div className="Total-Box5" onClick={() => handleNavigate('/admindevelopment')}>
                    <FaVideo className="icon" />
                        <h2>Development Updates Video</h2>
                        <p>{developmentUpdatesVideoCount}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TotalBlogs;
