import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../../components/AdminHeader';
import Sidebar from '../../components/AdminSidebar';
import ReactLoading from '../../../../assets/React-Loading.gif';
import '../../PannelStyling/AdminNews.css';

const RolePermissions = () => {
    const location = useLocation();
    const { email, role, userRole, userPermissions } = location.state || {};
    const [permissions, setPermissions] = useState({
        dashboard: false,
        manageWebRoutes: false,
        adminmanagement: false,
        subModules: [
            { id: 1, name: 'News/Events', isChecked: false },
            { id: 2, name: 'Development Updates', isChecked: false },
            { id: 3, name: 'Latest Headlines', isChecked: false },
            { id: 4, name: 'About Us', isChecked: false },
            { id: 5, name: 'Footer', isChecked: false },
            { id: 6, name: 'Project Location', isChecked: false },
            { id: 7, name: 'Authorized Sales Partner', isChecked: false },
            { id: 8, name: 'Pricing', isChecked: false },
            { id: 9, name: 'Contact Us', isChecked: false },
            { id: 10, name: 'PopUps', isChecked: false },
            { id: 11, name: 'FAQs', isChecked: false },
            { id: 12, name: 'Social Media Platforms', isChecked: false },
            { id: 13, name: 'Gallery', isChecked: false },
            { id: 14, name: 'Contact Details', isChecked: false },
            { id: 15, name: 'Project Amenities', isChecked: false }
        ]
    });
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [isWebRoutesSubMenuOpen, setIsWebRoutesSubMenuOpen] = useState(false);

    useEffect(() => {
        if (!email ) {
            setError(true);
        } else {
            fetchPermissions();
        }
    }, [email, role]);

    const fetchPermissions = async () => {
        try {
            const response = await fetch(`https://d1server.d1capitalparkcity.com/fetch-permissions-manage/${role}`);
            const data = await response.json();
    
            if (Array.isArray(data.permissions)) {
                setPermissions(prevState => ({
                    ...prevState,
                    dashboard: data.permissions.includes(0),
                    manageWebRoutes: data.permissions.includes(1),
                    adminmanagement: data.permissions.includes(100),
                    subModules: prevState.subModules.map(module => ({
                        ...module,
                        isChecked: data.permissions.includes(module.id+1) // Use `module.id` directly
                    }))
                }));
            } else {
                console.error('Unexpected data format:', data);
            }
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };
    
    const handleCheckboxChange = (id) => {
        setPermissions(prevState => {
            let newState = { ...prevState };
            if (id === 'dashboard') {
                newState.dashboard = !prevState.dashboard;
            }else if(id === 'adminmanagement'){
                newState.adminmanagement = !prevState.adminmanagement;
            } else if (id === 'manageWebRoutes') {
                newState.manageWebRoutes = !prevState.manageWebRoutes;
                newState.subModules = newState.subModules.map(module => ({
                    ...module,
                    isChecked: newState.manageWebRoutes
                }));
            } else {
                newState.subModules = newState.subModules.map(module =>
                    module.id === id ? { ...module, isChecked: !module.isChecked } : module
                );
                newState.manageWebRoutes = newState.subModules.some(module => module.isChecked);
            }
            return newState;
        });
    };

    const savePermissions = async () => {
        const permissionsToSave = [
            ...(permissions.dashboard ? [0] : []),
            ...(permissions.adminmanagement ? [100] : []),
            ...(permissions.manageWebRoutes ? [1] : []),
            ...permissions.subModules
                .filter(module => module.isChecked)
                .map(module => module.id + 1)
        ];

        try {
            const response = await fetch('https://d1server.d1capitalparkcity.com/update-permissions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    role: role,
                    permissions: permissionsToSave
                }),
            });

            if (response.ok) {
                alert('Permissions updated successfully');
            } else {
                alert('Failed to update permissions');
            }
        } catch (error) {
            console.error('Error saving permissions:', error);
            alert('Error saving permissions');
        }
    };

    const getRoleString = (role) => {
        switch (role) {
            case 1:
                return 'Super Admin';
            case 2:
                return 'Admin';
            case 3:
                return 'Moderator';
            case 4:
                return 'Content Editor';
            case 5:
                return 'Customer Support';
            default:
                return 'Not Assigned';
        }
    };

    const toggleWebRoutesSubMenu = () => {
        setIsWebRoutesSubMenuOpen(!isWebRoutesSubMenuOpen);
    };

    if (error) {
        return (
            <>
                <div className='AdminNews-Header'>
                    <Header />
                </div>
                <h1 style={{ textAlign: 'center', marginTop: '20vh' }}>Error 404: Page Not Found!</h1>
            </>
        );
    }

    return (
        <div className='AdminNews-Container'>
            <div className='AdminNews-Header'>
                <Header />
            </div>
            <div className='AdminContent'>
                <div className='AdminNews-Sidebar'>
                <Sidebar 
                   email={email || ''} 
                   userRole={userRole || ''} 
                   userPermissions={userPermissions || []} 
                 /> 
                </div>
                <div className='AdminNews-Main'>
                    <Helmet>
                        <title>D1 Capital Park City | Manage Permissions</title>
                        <meta name="description" content="Manage permissions for different roles." />
                        <link rel='canonical' href='https://d1capitalparkcity.com/manage-permissions' />
                    </Helmet>
                    <Suspense fallback={<div className='loading-container'><img src={ReactLoading} alt="Loading..." /></div>}>
                        <div>
                            <h1 className="h1stake">Manage Permissions</h1>
                        </div>
                        <div className='Permissions-List'>
                            <div className="permissions-card">
                                <h2 className="role-name">Role: {getRoleString(role)}</h2>
                                <div className="table-container" style={{ display: 'flex', justifyContent: 'center',marginTop:'50px' }}>
                                    <table className="table table-striped" style={{ width: '50%', borderCollapse: 'collapse',border:'2px solid black' }}>
                                       <thead>
                                            <tr>
                                                <th style={{ fontSize:'20px'}}>Module</th>
                                                <th style={{ fontSize:'20px'}}>Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td  style={{ fontSize:'20px'}}>Dashboard</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={permissions.dashboard}
                                                        onChange={() => handleCheckboxChange('dashboard')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td onClick={toggleWebRoutesSubMenu} style={{ cursor: 'pointer', fontSize:'20px' }}>Web Management</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={permissions.manageWebRoutes}
                                                        onChange={() => handleCheckboxChange('manageWebRoutes')}
                                                    />
                                                </td>
                                            </tr>
                                            {isWebRoutesSubMenuOpen && permissions.subModules.map(module => (
                                                <tr key={module.id}>
                                                    <td>{module.name}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={module.isChecked}
                                                            onChange={() => handleCheckboxChange(module.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                            <td style={{ cursor: 'pointer', fontSize:'20px' }}>Admin Management</td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={permissions.adminmanagement}
                                                        onChange={() => handleCheckboxChange('adminmanagement')}
                                                    />
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </div>
                                <button className='btn42-43 btn-42' onClick={savePermissions}>Save</button>

                            </div>
                        </div>
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default RolePermissions;
