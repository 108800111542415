import React from 'react'
import './LoginDetail.css'

const LoginDetail = () => {
  return (
    <div className='Logindetail-Container'>
<h3>ہے ابتدا ایک نئی دنیا کی</h3>
      <h2>Welcome To D1 Capital Park City Admin Pannel</h2>
      
    </div>
  )
}

export default LoginDetail
