import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';

const AdminSocialMedia = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [socialMediaData, setSocialMediaData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true); // Set error to true if email is not provided
          } else {
        fetchData();
          }
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_social_media');
            if (response.status === 200) {
                const data = response.data;
                setSocialMediaData(data);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(true);
        }
    };

    const handleEdit = (id) => {
        navigate('/editsocialmedia', { state: { id, email, userRole, userPermissions } });
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Social Media entry?');
        if (!confirmDelete) {
            return;
        }
    
        try {
            await axios.delete(`https://d1server.d1capitalparkcity.com/delete_social_media/${id}`);
            fetchData();
        } catch (error) {
            console.error('Error deleting Social Media entry:', error);
            // Handle error
        }
    };

    const handleAddNewSocialMedia = () => {
        navigate('/addsocialmedia', { state: { email, userRole, userPermissions } });
    };

    return (
        <div className='AdminNews-Container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent' style={{ display: 'flex', maxWidth: '1200px', margin: '20px auto' }}>
                    <div className='AdminNews-Sidebar' style={{ flex: '1' }}>
                    <Sidebar 
                     email={email || ''} 
                     userRole={userRole || ''} 
                     userPermissions={userPermissions || []} 
                    /> 
                    </div>
                    <div className='AdminNews-Main' style={{ flex: '3', paddingLeft: '20px' }}>
                        <h1 className="h1stake">Social Media Entries</h1>
                        <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddNewSocialMedia}>Add New Social Media Entry</button>
                        </div>
                        <div className="table-styling">
                            <table className="table table-striped" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Category</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '50%' }}>Link</th>
                                        <th style={{ border: '1px solid black', padding: '8px', width: '30%' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {socialMediaData.map(entry => (
                                        <tr key={entry._id}>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{entry.category}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>{entry.link}</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                            <div style={{padding: '8px'}}>
                                            <td>
                                                <button className="btn btn-primary" style={{ marginRight: '5px' }} onClick={() => handleEdit(entry._id)}>Edit</button>
                                                </td><td>
                                                <button className="btn btn-danger" onClick={() => handleDelete(entry._id)}>Delete</button>
                                            </td></div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdminSocialMedia;
