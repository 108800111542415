import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddNewMember = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [image, setImage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    index: '' // Add index to formData state
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!email) {
      setError(true);
      return;
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('profile', image);
      formDataToSend.append('name', formData.name);
      formDataToSend.append('designation', formData.designation);
      formDataToSend.append('index', formData.index); // Append index to form data

      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_aboutus', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('New member added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminaboutus', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error adding new member:', error.response.data);
      alert(error.response.data.message);
     // alert('Sorry for the Inconvenience, a Technical Issue Occurred.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
           <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Add New Member</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <label>Profile Image:</label>
              <input
                type="file"
                name="profile"
                onChange={(e)=>setImage(e.target.files[0])}
                required
              />
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
              <label>Designation:</label>
              <input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                placeholder="Designation"
                required
              />
              <label>Index:</label> {/* Input field for the index */}
              <input
                type="text"
                name="index"
                value={formData.index}
                onChange={handleChange}
                placeholder="Index"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewMember;
