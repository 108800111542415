import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../PannelStyling/AdminHeader.css';

import { IKImage } from 'imagekitio-react';

const MemberHeader = () => {
  const [zIndex, setZIndex] = useState(1);

  const handleButtonClick = () => {
    // Toggle zIndex between 1 and 102 on button click
    setZIndex(prevZIndex => (prevZIndex === 1 ? 102 : 1));
  };

  return (
    <div className="Admin-header-container">
      <div className={`Admin-navbar ${zIndex === 102 ? 'higher-zIndex' : ''}`}>
        <div className="Admin-logo-container">
        <IKImage
          path="/D1logo.png.png?updatedAt=1708775472098"
          publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
          urlEndpoint='https://ik.imagekit.io/HamzaMalik'
          className="Admin-logo-image"
        />
          
            <span className="Admin-navbar-brand"> D1 CAPITAL PARK CITY</span>

        </div>
       {/* <button 
          className="navbar-toggler" 
          type="button" 
          onClick={handleButtonClick} // Call handleButtonClick on button click
        >
          <span className="navbar-toggler-icon"></span>
  </button>*/}
      {/* <div className="navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us">About Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">Contact Us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/pricing">Pricing</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gallery">Gallery</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link webportal-link" to="/login">Web Portal</Link>
            </li>
          </ul>
</div>*/}
      </div>
    </div>
  );
};

export default MemberHeader;
