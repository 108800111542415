import React, { useState, useEffect, Suspense, lazy } from 'react';
import Header from '../components/Header';
import phata from '../assets/phata.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import Footer from '../components/Footer';
import Salespartner from '../JsonDoc/Salespartner.json';
import '../styles/AuthorizesSalesPartner.css';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';
import SalesPop from '../components/NewsEvents/SalesPop.js';
import axios from 'axios';


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Lazy load Slider component
const LazySlider = lazy(() => import("react-slick"));

const AuthorizesSalesPartner = () => {
  const [salesPartner, setSalesPartner] = useState([]);
  const [showPopup, setShowPopup] = useState(true); 
  const [popupDataAvailable, setPopupDataAvailable] = useState(false);


  useEffect(() => {
    const fetchSalesPartner = async () => {
      try {
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_partner');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
    //setSalesPartner(Salespartner); //From json file  
      setSalesPartner(data);  //from db
       
      } catch (error) {
        console.error('Error fetching sales partners:', error);
      }
    };

    fetchSalesPartner();
  }, []);


  useEffect(() => {
    // Fetch popup data from the API
    const fetchPopupData = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_popups?type=SalesPartner');
        if (response.data.length > 0) {
          setPopupDataAvailable(true); // Set to true if data is available
        } else {
          setPopupDataAvailable(false); // Set to false if no data is available
        }
      } catch (error) {
        console.error('Error fetching pop-ups:', error);
        alert('Technical Issue Occurred While Fetching Pop data.');
      }
    };

    fetchPopupData();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  var settings = {
    dots:true,
    infinite:true,
    speed:1000,
    slidesToShow:4,
    slidesToScroll:1,
    autoplay:true, // Enable autoplay
    autoplaySpeed:500,// Set autoplay speed in milliseconds (e.g., 3000ms = 3 seconds)
    cssEase:"ease", // Smooth transition effect
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | Our Sales Partner</title>
        <meta name="description" content="Authorized Sales Partner of D1 Capital Park City."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/authorizedpartner'/>
      </Helmet>
    <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>
      <div className="AuthorizesSalesPartner">
        <div className='partner-heading'>
          <h1 className='h1stakepartner'>OUR AUTHORIZED SALES PARTNER</h1>
          <p>"At D1 Capital Park City, we value the integral role of our Authorized Sales Partners in extending our exceptional products and services to customers worldwide. Through our partnership program, they gain access to exclusive resources, continuous support, and lucrative growth opportunities. Together, we cultivate enduring relationships founded on trust, reliability, and shared success. Join us as an Authorized Sales Partner and embark on a rewarding journey of collaboration and prosperity."</p>
        </div>
        <div className='partner-container'>
            <LazySlider {...settings}>
              {salesPartner.map((partner, index) => (
                <div key={index} className='partner-card'>
                  <img src={partner.logo} alt={partner.name} className='partner-logo' loading='lazy' />
                  <h3 className='partner-name'>{partner.name}</h3>
                  <p className='partner-description'>{partner.description}</p>
                </div>
              ))}
            </LazySlider>
          
          <br/>
        </div>
      </div>
      {popupDataAvailable && showPopup && <SalesPop onClose={handleClosePopup} />}

      <Whatsapp />
      <Footer />
      </Suspense>
    </div>
  );
};

export default AuthorizesSalesPartner;
