import React, { useState, useEffect } from 'react';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar'; // Import Sidebar if needed
import { useLocation, useNavigate } from 'react-router-dom';
import '../../PannelStyling/AdminNews.css';
import '../../../../styles/Developmentupdates.css';
import axios from 'axios';
import ReactPlayer from 'react-player';

const AdminDevelopment = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [developmentUpdates, setDevelopmentUpdates] = useState([]);

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_development_updates');
        setDevelopmentUpdates(response.data.reverse()); // Assuming the response.data is an array of development updates
      } catch (error) {
        console.error('Error fetching development updates:', error);
        setError(true); // Set error to true if there's an error fetching data
      }
    };
  
    fetchData();
  }, [email]);


  const handleRemoveDevelopment = async (id) => {
    // Ask for confirmation before deleting the article
    const confirmDelete = window.confirm('Are you sure you want to delete this article?');
    if (!confirmDelete) {
        return; // If user cancels, do nothing
    }

    try {
        // Send DELETE request to delete the article
        await fetch(`https://d1server.d1capitalparkcity.com/delete_development_update/${id}`, {
            method: 'DELETE',
        });

        // After successful removal, fetch and update the news events
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_development_updates');
        const data = await response.json();
        setDevelopmentUpdates(data.reverse());
    } catch (error) {
        console.error('Error removing article:', error);
    }
};


const handleAddNewUpdate = () => {
    navigate('/adddevelopmentupdate', { state: { email, userRole, userPermissions } });
};

const handleEditDevelopment = (id) => {
  navigate('/editdevelopmentupdate', { state: { id, email, userRole, userPermissions } });
};

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Development Updates</h1>
            <p style={{ color: 'red' }}><b>Note: Image Should be uploaded at Image kit and size should be (540x540)</b></p>

            <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddNewUpdate}>Add Development Updates</button>
              </div>
            {/* Render development updates */}
            <ul style={{ marginLeft:'150px'}}>
              <div className='development-new-container'>
                <div className="development-updates-container">
                  {/* Render videos first */}
                  {developmentUpdates
                    .filter(update => update.type === 'video')
                    .map(update => (
                      <div key={update.id} className="development-update-card">
                        <ReactPlayer url={update.videoSrc} controls={true} width="100%" height="auto" />
                        <h2 className="development-card-heading">{update.heading}</h2>
                        <p className="development-card-video-details">{update.details}</p>
                        <div className='News-Buttons'>
                            <button className="btn42-43 btn-42" onClick={() => handleEditDevelopment(update._id)}>Edit Article</button>
                            <button className="btn42-43 btn-42" onClick={() => handleRemoveDevelopment (update._id)}>Delete</button>
                         </div>
                      </div>
                      
                    ))}
                </div>

                <div className="development-updates-container">
                  {/* Render images after videos */}
                  {developmentUpdates
                    .filter(update => update.type === 'image')
                    .map(update => (
                      <div key={update.id} className="development-update-card">
                        <img src={update.image} alt={update.heading} className="development-card-image" loading='lazy'/>
                        <h2 className="development-card-heading">{update.heading}</h2>
                        <p className="development-card-details">{update.details}</p>
                        <div className='News-Buttons'>
                            <button className="btn42-43 btn-42" onClick={() => handleEditDevelopment(update._id)}>Edit Article</button>
                            <button className="btn42-43 btn-42" onClick={() => handleRemoveDevelopment (update._id)}>Delete</button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDevelopment;
