import React, {useEffect, useState,lazy,Suspense}from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer';
import phata from '../assets/phata.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import Whyd1 from '../components/ProjectLocation/Whyd1';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';

import OurLocation from '../components/ProjectLocation/OurLocation';
const Locationmap = lazy(() => import('../components/ProjectLocation/LocationMap'));

const Projectlocation = () => {

    const [scrollPosition, setScrollPosition] = useState(0);

 
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  

    return (
        <div >
          <Helmet>
        <title>D1 Capital Park City | Project Location</title>
        <meta name="description" content="Prime Location near International Islamabad Airport."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/location'/>
      </Helmet>
       <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
           <div className="Header"><Header />
           <div className="phata">
          <img src={phata} alt="Phata" />
        </div>
           </div>

          
<div>
<h1 className='h1stake'>A WONDERFUL LOCATION</h1>
  <OurLocation/></div>




  <div>
<h1 className='h1stake'>UNDERSTANDING THE LOCATION</h1>
  <Locationmap/></div>

   

   {scrollPosition > 500 && (
          <div className='whyus'>
          <h1 className='h1stake'>WHY D1 CAPITAL PARK CITY?</h1>
          <Whyd1/>
          </div>
          )}
   
    
     <Whatsapp/>
    <br/>
     <div> 
                <Footer/>
          </div>
          </Suspense>
        </div>
      )
}

export default Projectlocation
