import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const EditPopup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    type: '',
    imageSrc: '',
    title: '',
    heading: '',
    details: [{ title: '', content: '' }],
  });

  useEffect(() => {
    const fetchPopup = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_popup/${id}`);
        const { type, imageSrc, title, heading, details } = response.data;
        setFormData({ type, imageSrc, title, heading, details });
      } catch (error) {
        console.error('Error fetching popup:', error);
        setError(true);
      }
    };

    if (!email) {
      setError(true); // Set error to true if email is not provided
    } else {
      fetchPopup();
    }
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDetailsChange = (index, e) => {
    const { name, value } = e.target;
    const newDetails = [...formData.details];
    newDetails[index][name] = value;
    setFormData({ ...formData, details: newDetails });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_popup/${id}`, formData);
      console.log('Popup updated successfully:', response.data);
      alert(response.data.message);
      navigate('/adminpopup', { state: { email, userRole, userPermissions } }); // Adjust the redirect route as necessary

    } catch (error) {
      console.error('Error updating popup:', error);
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />           <h1 className="h1stake" >Update Popup</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <input
                type="text"
                name="type"
                value={formData.type}
                onChange={handleChange}
                placeholder="Popup Type"
                required
              />
              <label>Image:</label>
              <input
                type="text"
                name="imageSrc"
                value={formData.imageSrc}
                onChange={handleChange}
                placeholder="Image Source URL"
              />
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Popup Title"
                required
              />
              <label>Heading:</label>
              <input
                type="text"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
                placeholder="Popup Heading"
                required
              />
              <label>Details:</label>
              {formData.details.map((detail, index) => (
                <div className='AddNews-Detial' key={index}>
                  <input
                    type="text"
                    name="title"
                    value={detail.title}
                    onChange={(e) => handleDetailsChange(index, e)}
                    placeholder="Detail Title"
                  />
                  <textarea
                    name="content"
                    value={detail.content}
                    onChange={(e) => handleDetailsChange(index, e)}
                    placeholder="Detail Content"
                  />
                </div>
              ))}
              <button type="button" onClick={() => setFormData({ ...formData, details: [...formData.details, { title: '', content: '' }] })}>
                Add Detail
              </button>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditPopup;
