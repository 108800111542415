import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Import CSS for styling

const FooterLinkEdit = () => {
    const location = useLocation();
    const { id, email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [heading, setHeading] = useState('');
    const [linkData, setLinkData] = useState([{ title: '', link: '' }]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
            return;
        }
        else if (id) {
            fetchFooterLink(id);
        }
    }, [id]);

    const fetchFooterLink = async (id) => {
        try {
            const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_footer_link/${id}`);
            const { heading, linkData } = response.data;
            setHeading(heading);
            setLinkData(linkData);
        } catch (error) {
            console.error('Error fetching footer link:', error);
            setError(true);
        }
    };

    const handleAddLinkData = () => {
        setLinkData([...linkData, { title: '', link: '' }]);
    };

    const handleRemoveLinkData = (index) => {
        const updatedLinkData = [...linkData];
        updatedLinkData.splice(index, 1);
        setLinkData(updatedLinkData);
    };

    const handleTitleChange = (index, value) => {
        const updatedLinkData = [...linkData];
        updatedLinkData[index].title = value;
        setLinkData(updatedLinkData);
    };

    const handleLinkChange = (index, value) => {
        const updatedLinkData = [...linkData];
        updatedLinkData[index].link = value;
        setLinkData(updatedLinkData);
    };

    const handleAddFooterLink = async () => {
        try {
            const newFooterLink = { heading, linkData };
            if (id) {
                await axios.put(`https://d1server.d1capitalparkcity.com/update_footer_link/${id}`, newFooterLink);
                alert("Footer Link Updated Successfully");
            } else {
                await axios.post('https://d1server.d1capitalparkcity.com/save_footer_link', newFooterLink);
                alert("Footer Link Added Successfully");
            }
            navigate('/adminfooterlink', { state: { email, userRole, userPermissions } }); // Redirect after successful addition or update
        } catch (error) {
            console.error('Error adding/updating footer link:', error);
            // Handle error as needed
        }
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                      email={email || ''} 
                      userRole={userRole || ''} 
                      userPermissions={userPermissions || []} 
                    /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">{id ? 'Edit Footer Link' : 'Add New Footer Link'}</h1>
                        <div className='AddNews-Form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <label htmlFor='heading'>Heading</label>
                                    <input
                                        type='text'
                                        id='heading'
                                        value={heading}
                                        onChange={(e) => setHeading(e.target.value)}
                                        required
                                        className='form-control'
                                    />
                                </div>
                                {linkData.map((link, index) => (
                                    <div key={index} className='link-data-group'>
                                        <div className='form-group'>
                                            <label htmlFor={`title-${index}`}>Title</label>
                                            <input
                                                type='text'
                                                id={`title-${index}`}
                                                value={link.title}
                                                onChange={(e) => handleTitleChange(index, e.target.value)}
                                                required
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor={`link-${index}`}>Link</label>
                                            <input
                                                type='text'
                                                id={`link-${index}`}
                                                value={link.link}
                                                onChange={(e) => handleLinkChange(index, e.target.value)}
                                                required
                                                className='form-control'
                                            />
                                        </div>
                                        <button type='button'  onClick={() => handleRemoveLinkData(index)}>Remove</button>
                                    </div>
                                ))}
                                <div>
                                <button type='button'  onClick={handleAddLinkData}>Add Link</button></div>
                                <div className='form-group'>
                                    <button onClick={handleAddFooterLink}>
                                        {id ? 'Update Footer Link' : 'Add Footer Link'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FooterLinkEdit;
