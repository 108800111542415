import React, { useState, useEffect, Suspense, lazy } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import phata from '../../assets/phata.gif';
import ReactLoading from '../../assets/React-Loading.gif';
import FloatingWhatsapp from '../../components/FloatingWhatsapp';
import { Helmet } from 'react-helmet-async';
import "../../styles/WebPortalUser/Login.css";
import { Link, useNavigate } from 'react-router-dom'; 
import LoginDetail from '../../components/WebPortaLoginSignup/LoginDetail.js'


const Footer = lazy(() => import('../../components/Footer'));

const Login = () => {
  const [fdata, setFdata] = useState({
    email: '',
    password: ''
  });
  const [errormsg, setErrormsg] = useState(null);
const navigate = useNavigate();




  const handleSubmit = (e) => {
    console.log(fdata);
    e.preventDefault();
    if (fdata.email === '' || fdata.password === '') {
      setErrormsg('All fields are required.*');
      return;
    } else {
      axios.post('https://d1server.d1capitalparkcity.com/adminsignin', fdata)
        .then(response => {
          if (response.data.error) {
            setErrormsg(response.data.error);
            alert(response.data.error);
          } else {
            localStorage.setItem('authToken', response.data.authToken);
            alert("You have Successfully Logged in.");
            const userRole = response.data.role;
            if (userRole === 1 || userRole === 2) {
              navigate('/dashboard', { state: { email: fdata.email } });
            } else if (userRole === 5) {
              navigate('/customerdashboard', { state: { email: fdata.email } });
            }else if (userRole === 4 || userRole === 3) {
              navigate('/contentdashboard', { state: { email: fdata.email } });
            } else {
              // Handle other roles or unknown roles
              alert('Unauthorized role');
            }
            
            setFdata({ email: '', password: '' });
          }
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            setErrormsg(error.response.data.error);
            alert(error.response.data.error);
          } else {
            alert("Sorry for the inconvenience! Technical issues occurred. Please try again later!");
            console.log(error);
          }
        });
    }
  }

  return (
    <div className='Login-Container'>
      <Helmet>
        <title>D1 Capital Park City | Admin Panel</title>
        <meta name="description" content="D1 Capital Park City Group and Team."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/login'/>
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <div className="Header">
        <Header />
        <div className="phata">
          <img src={phata} alt="Phata" loading="lazy" />
        </div>
      </div>
      <div>
        <h1 className='h1stake'>Admin Panel</h1>
      </div>
      <div className='Login-Page-Detail-Container'>
        <div><LoginDetail/></div>
        <div className='Login-Page'>
          <form onSubmit={handleSubmit}>
            <h2>Log In</h2>
            <h5>Sign in to Continue</h5>
            <div>
              <label htmlFor='email'>Email:</label>
              <input
                type='email'
                id='email'
                value={fdata.email}
                placeholder='Please enter your Email'
                onFocus={() => setErrormsg(null)}
                onChange={(e) => setFdata({ ...fdata, email: e.target.value })}
                required
              />
            </div>
            <div>
              <label htmlFor='password'>Password:</label>
              <div className="password-input">
                <input
                  type='password'
                  id='password'
                  value={fdata.password}
                  placeholder='Please enter your Password'
                  onFocus={() => setErrormsg(null)}
                  onChange={(e) => setFdata({ ...fdata, password: e.target.value })}
                  required
                />
              </div>
            </div>
            <button className='btn42-43 btn-42' type='submit'>Login</button>
            {/* Uncomment the following lines if you have a signup link */}
            {/* <p>Don't have an Account?<span style={{ color: 'blue', cursor: 'pointer' }}> <Link to={'/signup'}>Sign Up</Link></span></p> */}
          </form>
        </div>
      </div>
      <FloatingWhatsapp />
      <br />
        <div>
          <Footer />
        </div>
      </Suspense>
    </div>
  )
}

export default Login;
