import React, { useState, useEffect } from 'react';
import '../../styles/MessageTesting.css';
import chairman8 from '../../assets/mfarooqdar.gif';
import ceo1 from '../../assets/CEOCompanies.gif';

import ReactLoader from '../../assets/React-Loading.gif';
import { Audio } from 'react-loader-spinner';
import axios from 'axios';

const MessageTesting = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_ourgroup');
        setMessages(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };
    fetchMessages();

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <Audio color="#FFD700" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="messagetest-box-container">
      

      {messages.map((message, index) => {
         if (index % 2 === 0) {
            return (
                <div key={index} className='messagetest-box2' >
                <div className='submessagetest-box2'>
                  <h3 className='h3test'>{message.Title}</h3>
                  <h3 className='hideh3test'>{message.Type}</h3>
                  <div><h4>{message.Name}</h4></div>
                  <div className='parastest'>
                    <p>{message.Message}</p>
                  </div>
                </div>
                <div className='messgaetest-image'>
                  <div>
                    <img src={message.ProfileImage} alt="AdditionalImage" loading="lazy" />
                  </div>
                  <div className="scrollabletest-content">
                   {/* <img className='chairman8' src={chairman8} alt="AdditionalImage" loading="lazy" />*/}
                    <div><p>{message.Experience}</p></div>
                  </div>
                </div>
              </div>
            );
          } 
        else if (index % 2 === 1) {
          return (
            <div key={index} className="messagetest-box2">
              <div className='messgaetest-image3'>
                <div className="scrollabletest-content2">
                 {/* <img src={ceo1} alt="AdditionalImage" loading="lazy" />*/}
                  <p>{message.Experience}</p>
                </div>
                <div className='ceotest'>
                  <img src={message.ProfileImage} alt="AdditionalImage" loading="lazy" />
                </div>
              </div>
              <div className="submessagetest-box">
                <h3 className='h32test'>{message.Title}</h3>
                <h3 className='hideh32test'>{message.Type}</h3>
                <div><h4>{message.Name}</h4></div>
                <div className='paras2test'>
                  <p>{message.Message}</p>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default MessageTesting;
