import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import phata from '../assets/phata.gif';
import { useParams } from 'react-router-dom';
import '../styles/NewseventDetail.css';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

const NewseventDetail = () => {
  const { id } = useParams(); // Retrieve the ID from the URL params
  const [selectedNewsEvent, setSelectedNewsEvent] = useState(null);

  useEffect(() => {
    const fetchNewsEvent = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_news_article/${id}`);
        setSelectedNewsEvent(response.data);
      } catch (error) {
        console.error('Error fetching news event:', error);
      }
    };

    fetchNewsEvent();
  }, [id]);

  // If no news event is found with the given ID, return a message
  if (!selectedNewsEvent) {
    return (
      <div>
        <Header />
        <div className='error'>News event not found.</div><br />
        <Footer />
      </div>
    );
  }

  // Render the details of the found news event
  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | News/Events Updates</title>
        <meta name="description" content="D1 Capital Park City Latest News and Events Updates." />
        <link rel='canonical' href='https://d1capitalparkcity.com//news/' />
      </Helmet>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>
      <div className="news-event-detail-container">
        <img src={selectedNewsEvent.imageSrc} alt={selectedNewsEvent.heading} className="news-event-detail-image" />
        <h1 className="news-event-detail-heading">{selectedNewsEvent.heading}</h1>
        <div className="news-event-detail-details">
          {selectedNewsEvent.details.map(section => (
            <div key={section.title}>
              <br />
              <h2>{section.title}</h2>
              <p>{section.content}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewseventDetail;
