import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddPopup = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    type: '', // Adding the type field
    imageSrc: '',
    title: '',
    heading: '',
    details: [{ title: '', content: '' }],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDetailsChange = (index, e) => {
    const { name, value } = e.target;
    const newDetails = [...formData.details];
    newDetails[index][name] = value;
    setFormData({ ...formData, details: newDetails });
  };

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]); // Fetch data when email changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_popup', JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Popup added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminpopup', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error adding Popup:', error.response.data);
      alert('Sorry, an error occurred while adding the popup.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Add New Popup</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Select Type</option>
                <option value="MainHome">Main Home</option>
                <option value="Welcome">Welcome</option>
                <option value="News">News</option>
                <option value="DevelopmentUpdate">Development Update</option>
                <option value="SalesPartner">Sales Partner</option>

              </select>
              <label>Image:</label>
              <input
                type="text"
                name="imageSrc"
                value={formData.imageSrc}
                onChange={handleChange}
                placeholder="Image Source URL"
              />
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Popup Title"
                required
              />
              <label>Heading:</label>
              <input
                type="text"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
                placeholder="Popup Heading"
                required
              />
              <label>Details:</label>
              {formData.details.map((detail, index) => (
                <div className='AddNews-Detial' key={index}>
                  <input
                    type="text"
                    name="title"
                    value={detail.title}
                    onChange={(e) => handleDetailsChange(index, e)}
                    placeholder="Detail Title"
                  />
                  <textarea
                    name="content"
                    value={detail.content}
                    onChange={(e) => handleDetailsChange(index, e)}
                    placeholder="Detail Content"
                  />
                </div>
              ))}
              <button type="button" onClick={() => setFormData({ ...formData, details: [...formData.details, { title: '', content: '' }] })}>
                Add Detail
              </button>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPopup;
