import React, { useState, useEffect, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Whatsapp from '../components/FloatingWhatsapp.js';
import phata from '../assets/phata.gif';
import '../styles/NewsEvent.css';
import ReactLoading from '../assets/React-Loading.gif';
import { Helmet } from 'react-helmet-async';
import NewsPop from '../components/NewsEvents/NewsPop.js';

const NewsEvents = () => {
  const [newsEvents, setNewsEvents] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const [headlines, setHeadlines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsEvents = async () => {
      try {
        // Update the fetch URL to match your server's endpoint
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_news_events');
        const data = await response.json();
        setNewsEvents(data.reverse()); 
      } catch (error) {
        console.error('Error fetching news events:', error);
      }
    };

    fetchNewsEvents();
  }, []);

  useEffect(() => {
    const fetchHeadlines = async () => {
      try {
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_headlines?Type=News');
        const data = await response.json();
        setHeadlines(data.reverse()); 
      } catch (error) {
        console.error('Error fetching headlines:', error);
      }
    };

    fetchHeadlines();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleReadArticle = (id) => {
    // Navigate to the next page with the default ID
    navigate(`/news/${id}`);
  };

  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | News/Events</title>
        <meta name="description" content="D1 Capital Park City Latest News and Events Updates."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/newsevent'/>
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>

      <div>
        <h1 className="h1stake">LATEST NEWS/EVENTS</h1>
      </div>

      <div className="headline-container">
        {headlines.map((headline, index) => (
          <div key={index} className="right-to-left-headline">
            <span className="development-red-text">{headline.Title}:</span>
            <span className="development-text">
              {headline.HeadlineDetail}
            </span>
          </div>
        ))}
      </div>
      <br />

      <div className="news-events-grid">
        {newsEvents.map((event, index) => (
          <div key={index} className="news-event-card">
            <img src={event.imageSrc} alt={event.heading} className="news-event-image" loading='lazy' />
            <h2 className="news-event-heading">{event.heading}</h2>
            <button className="btn42-43 btn-42" onClick={() => handleReadArticle(event._id)}>Read Article</button>
          </div>
        ))}
      </div>
      {showPopup && <NewsPop onClose={handleClosePopup} />}
        <Whatsapp/>
      <Footer />
      </Suspense>
    </div>
  );
};

export default NewsEvents;
