import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';

const AdminPopup = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [popups, setPopups] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
          setError(true); 
          return;
        }

        // Fetch pop-ups from the API
        const fetchPopups = async () => {
            try {
                const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_popups'); 
                console.log(response.data);
                setPopups(response.data.reverse()); 
            } catch (error) {
                console.error('Error fetching pop-ups:', error);
                setError(true); // Set error to true if there's an error fetching pop-ups
            }
        };

        fetchPopups();

    }, [email]);


    const handleRemovePopUp = async (id) => {
      // Ask for confirmation before deleting the article
      const confirmDelete = window.confirm('Are you sure you want to delete this article?');
      if (!confirmDelete) {
          return; // If user cancels, do nothing
      }
  
      try {
          // Send DELETE request to delete the article
          await fetch(`https://d1server.d1capitalparkcity.com/delete_popup/${id}`, {
              method: 'DELETE',
          });
  
          // After successful removal, fetch and update the news events
          const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_popups');
          const data = await response.json();
          setPopups(data.reverse());
      } catch (error) {
          console.error('Error removing article:', error);
      }
  };


    const handleAddNewPopup = () => {
        navigate('/addpopup', { state: { email, userRole, userPermissions } });
    };
    const handleEditPopUp = (id) => {
      navigate('/editpopup', { state: { id, email, userRole, userPermissions } });
  };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                       email={email || ''} 
                       userRole={userRole || ''} 
                       userPermissions={userPermissions || []} 
                     /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">Pop Ups</h1>
                        <p style={{ color: 'red' }}><b>Note: Type define the position or location of the Pop Up!</b></p>
                        <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddNewPopup}>Add New Popup</button>
                        </div>
                        <div className="adminnews-events-grid">
                        {popups.map((popup, index) => (
                                   <div key={index} className="news-event-card">
                                     <h2>Type: {popup.type}</h2>
                                       <h2>{popup.title}</h2>
                                       <h3>{popup.heading}</h3>
                                       {/* Render each detail separately */}
                                       {popup.details.map((detail, detailIndex) => (
                                           <div key={detailIndex}>
                                               <p style={{ whiteSpace: 'pre-line' }}><strong>{detail.title}</strong> </p>
                                               <p style={{ whiteSpace: 'pre-line' }}>{detail.content}</p>
                                           </div>
                                       ))}
                                       <div className='News-Buttons'>
                                    <button className="btn42-43 btn-42" onClick={() => handleEditPopUp(popup._id)}>Edit</button>
                                    <button className="btn42-43 btn-42" onClick={() => handleRemovePopUp(popup._id)}>Delete</button>
                                    </div>
                                   </div>
                                   
                               ))}
                                  
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdminPopup;
