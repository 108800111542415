
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PageView.css';
import { FaUsers} from 'react-icons/fa'; // Import Font Awesome icons


const PageView = () => {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
      const fetchVisitorCount = async () => {
          try {
              const response = await axios.get('https://d1server.d1capitalparkcity.com/visitorcount');
              setVisitorCount(response.data.count);
          } catch (err) {
              console.error('Error fetching visitor count:', err);
          }
      };

      fetchVisitorCount();

      // Set up an interval to fetch updated count every few seconds
      const interval = setInterval(fetchVisitorCount, 5000); // Update every 5 seconds

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
  }, []);

  return (
      <div className='pageview-container'>
          <h3>This Website is Visited by</h3>
          <h1>{visitorCount}</h1>
          <h3>Visitors</h3>
          <FaUsers className="pageview-icon" />

      </div>
  );
};
export default PageView;
