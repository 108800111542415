import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../PannelStyling/AdminSidebar.css';
import { RiAdminFill,RiDashboardFill } from "react-icons/ri";
import { LuTableProperties } from "react-icons/lu";
import { MdOutlinePages, MdManageAccounts, MdDomainVerification } from "react-icons/md";
import { ImUsers, ImProfile } from "react-icons/im";
import { BiLogOut } from "react-icons/bi";

const MemberSidebar = ({ email, userRole, userPermissions }) => {
  const navigate = useNavigate(); // Initialize navigate
  const [isAccountsDropdownOpen, setIsAccountsDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isPropertiesDropdownOpen, setIsPropertiesDropdownOpen] = useState(false);
  const [isManagementDropdownOpen, setIsManagementDropdownOpen] = useState(false);
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);
  const [isUserRightsDropdownOpen, setIsUserRightsDropdownOpen] = useState(false);
  const [isAboutusDropdownOpen, setIsAboutUsDropdownOpen] = useState(false);
  const [isFooterDropdownOpen, setIsFooterDropdownOpen] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
 
  const [isLoading, setIsLoading] = useState(true);

  // ... (keep all your existing state variables for dropdowns)


 const hasPermission = (permissionValue) => {
    if (userRole === 1) return true;
    return userPermissions.some(p => p.value === permissionValue);
  };
 

const toggleAccountsDropdown = () => {
    setIsAccountsDropdownOpen(!isAccountsDropdownOpen);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsManagementDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);

  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
    setIsAccountsDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsManagementDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);

  };

  const togglePropertiesDropdown = () => {
    setIsPropertiesDropdownOpen(!isPropertiesDropdownOpen);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsManagementDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);

  };

  const toggleManagementDropdown = () => {
    setIsManagementDropdownOpen(!isManagementDropdownOpen);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);

  };

  const toggleAboutusDropdown = () => {
    setIsAboutUsDropdownOpen(!isAboutusDropdownOpen);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);
    setIsFooterDropdownOpen(false);
  };
  const toggleFooterDropdown = () => {
    setIsAboutUsDropdownOpen(false);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);
    setIsFooterDropdownOpen(!isFooterDropdownOpen);
  };
  const toggleLocationDropdown = () => {
    setIsAboutUsDropdownOpen(false);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsAdminDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);
    setIsFooterDropdownOpen(false);
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
  };

  const toggleAdminDropdown = () => {
    setIsAdminDropdownOpen(!isAdminDropdownOpen);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsManagementDropdownOpen(false);
    setIsUserRightsDropdownOpen(false);
  };

  const toggleUserRightsDropdown = () => {
    setIsUserRightsDropdownOpen(!isUserRightsDropdownOpen);
    setIsAccountsDropdownOpen(false);
    setIsProfileDropdownOpen(false);
    setIsPropertiesDropdownOpen(false);
    setIsManagementDropdownOpen(false);
    setIsAdminDropdownOpen(false);
  };




  const navigateWithState = (path) => {
    navigate(path, { state: { email, userRole, userPermissions } });
  };
  const navigateWithStateId = (path, role) => {
    navigate(path, { state: { email,role, userRole, userPermissions } });
  };

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.removeItem('authToken');
      // Clear entire browser history
      window.history.replaceState(null, '', '/');
      console.log('Logging out...');
      // Navigate to the admin route after clearing the history
      navigate('/d11426', { replace: true });
    } else {
      console.log('Logout canceled.');
    }
  };
  
  if (!localStorage.getItem('authToken')) {
    navigate('/d11426', { replace: true });
    return null; // or return a loading indicator, etc.
  }

  const getDashboardLink = () => {
    if (userRole === 1 || userRole === 2) {
      return '/dashboard';
    } else if (userRole === 4 || userRole === 3) {
      return '/contentdashboard';
    } 
    else if (userRole === 5) {
      return '/customerdashboard';
    }else {
      return '/contentdashboard'; // Default or fallback route
    }
  };
  
  return (
    <div className="sidebar">
      <ul className="sidebar-menu">
        
        {(userRole === 1 || hasPermission(0)) && (
       
       <li onClick={() => navigateWithState(getDashboardLink())}>
            <RiDashboardFill /> Dashboard
          </li>
        )}
        
        {(userRole === 1 || hasPermission(1)) && (
          <li className="dropdown-toggle" onClick={toggleManagementDropdown}>
            <MdOutlinePages /> Web Management
          </li>
        )}
        {isManagementDropdownOpen && (userRole===1 || hasPermission(1)) && (
          <ul className="sidebar-submenu">
            {(userRole === 1 || hasPermission(2)) && <li onClick={() => navigateWithState('/adminnews')}>News/Events</li>}
            {(userRole === 1 || hasPermission(3)) && <li onClick={() => navigateWithState('/admindevelopment')}>Development Updates</li>}
            {(userRole === 1 || hasPermission(4)) && <li onClick={() => navigateWithState('/adminheadline')}>Latest Headlines</li>}
            
            {(userRole === 1 || hasPermission(5)) && (
              <li className="dropdown-toggle" onClick={toggleAboutusDropdown}> About US </li>
            )}
            {isAboutusDropdownOpen && (userRole === 1 || hasPermission(5)) && (
              <ul className="sidebar-submenu">
                <li onClick={() => navigateWithState('/adminourgroup')}>Our Group</li>
                <li onClick={() => navigateWithState('/adminaboutus')}>Our Team</li>
              </ul>
            )}

            {(userRole === 1 || hasPermission(6)) && (
              <li className="dropdown-toggle" onClick={toggleFooterDropdown}> Footer </li>
            )}
            {isFooterDropdownOpen && (userRole === 1 || hasPermission(6) )&& (
              <ul className="sidebar-submenu">
                <li onClick={() => navigateWithState('/adminfooterpara')}>Footer Paragraphs</li>
                <li onClick={() => navigateWithState('/adminfooterlink')}>Footer Links</li>
              </ul>
            )}

            {(userRole === 1 || hasPermission(7) )&& (
              <li className="dropdown-toggle" onClick={toggleLocationDropdown}>Project Location</li>
            )}
            {isLocationDropdownOpen && (userRole === 1 || hasPermission(7)) && (
              <ul className="sidebar-submenu">
                <li onClick={() => navigateWithState('/adminwonderlocation')}>Wonderful Location</li>
                <li onClick={() => navigateWithState('/adminunderstandinglocation')}>Understanding Location</li>
                <li onClick={() => navigateWithState('/adminfooterlink')}>Why D1?</li>
              </ul>
            )}

            {(userRole === 1 || hasPermission(8)) && <li onClick={() => navigateWithState('/adminsalespartner')}>Authorized Sales Partner</li>}
            {(userRole === 1 || hasPermission(9)) && <li onClick={() => navigateWithState('/adminpricing')}>Pricing</li>}
            {(userRole === 1 || hasPermission(10)) && <li onClick={() => navigateWithState('/admincontacusdata')}>Contact Us</li>}
            {(userRole === 1 || hasPermission(11)) && <li onClick={() => navigateWithState('/adminpopup')}>PopUps</li>}
            {(userRole === 1 || hasPermission(12)) && <li onClick={() => navigateWithState('/adminfaqs')}>FAQs</li>}
            {(userRole === 1 || hasPermission(13)) && <li onClick={() => navigateWithState('/adminsocialmedia')}>Social Media Platforms</li>}
            {(userRole === 1 || hasPermission(14)) && <li onClick={() => navigateWithState('/admingallery')}>Gallery</li>}
            {(userRole === 1 || hasPermission(15)) && <li onClick={() => navigateWithState('/admincontactdetail')}>Contact Details</li>}
            {(userRole === 1 || hasPermission(16)) && <li onClick={() => navigateWithState('/projectamenitiesadmin')}>Project Amenities</li>}
          </ul>
        )}
        
        {(userRole === 1 || hasPermission(100)) && (
            <li className="dropdown-toggle" onClick={toggleAdminDropdown}>
              <RiAdminFill /> Admin Management
            </li>)}
            {isAdminDropdownOpen &&(userRole === 1 || hasPermission(100))&& (
              <ul className="sidebar-submenu">
                <li onClick={() => navigateWithState('/adminlist')}>Admins List</li>
                <li onClick={() => navigateWithState('/addadmin')}>Add New Admin</li>
                <li onClick={() => navigateWithState('/adminpassword')}>Update Admiin Password</li>

              </ul>
            )}
 {userRole === 1 && (
            <li className="dropdown-toggle" onClick={toggleUserRightsDropdown}>
              <ImUsers /> User Rights
            </li>)}
            {isUserRightsDropdownOpen && userRole === 1 && (
              <ul className="sidebar-submenu">
                <li onClick={() => navigateWithStateId('/rolepermission', 2)}>Admin</li>
                <li onClick={() => navigateWithStateId('/rolepermission', 3)}>Moderator</li>
                <li onClick={() => navigateWithStateId('/rolepermission', 4)}>Content Editor</li>
                <li onClick={() => navigateWithStateId('/rolepermission', 5)}>Customer Support</li>
              </ul>
            
        )}

        <li className="dropdown-toggle" onClick={toggleProfileDropdown}>
          <ImProfile /> Profile
        </li>
        {isProfileDropdownOpen && (
          <ul className="sidebar-submenu">
            <li onClick={() => navigateWithState('/profile')}>My Profile</li>
            <li onClick={() => navigateWithState('/changepassword')}>Change Password</li>
          </ul>
        )}
      </ul>

      <button className="logout" onClick={handleLogout}><BiLogOut /> Signout</button>
    </div>
  );
};


export default MemberSidebar;
