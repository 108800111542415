import React from 'react'
import "../../styles/Whyd1.css"
import stamp from '../../assets/stamp.png'
import location from '../../assets/location.mp4'

const whyd1 = () => {

  

  return (
    <div  className="container-whyd1" >
      <div className='box1'>
      <div className='stamp'>
<img src={stamp} alt='stamp'/>
       </div>
    <p>Embrace a lifestyle of ease and elegance at D1 Capital Park City. </p>
    <p>Strategically positioned near the International Airport in Islamabad.</p>
    <p>Situated in the prime location Near New International Airport Islamabad, our project is NOC/LOP approved, ensuring a secure investment and a hassle-free experience.</p>
    <p>We take pride in partnering with the finest developers, employing expert town planners, and offering competitive pricing, making luxury living both accessible and affordable.</p>
    <p>Committed to excellence, we guarantee the confirmed delivery of your dream home within four years.</p>
    <p>Choose D1 Capital Park City for an extraordinary living experience that seamlessly blends the best in location, security, design, affordability, and timely delivery.</p>
</div>



        <div className='box2'>
       
       <video controls width='100%' height='auto'>
          <source src={location} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
       
        </div>
      
    </div>
  )
}

export default whyd1
