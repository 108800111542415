import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddPricePlan = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    block: '',
    category: '',
    costOfLand: '',
    downPayment: '',
    monthlyInstallments: '',
    confirmation: '',
    biAnnualInstallments: '',
    annualInstallments: '',
    uponBallot: '',
    uponPossession: '',
    developmentcharges: '',
    miscellaneous: ''
  });

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]); // Fetch data when email changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_price_plan', formData);
      console.log('Price Plan added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminpricing', { state: { email, userRole, userPermissions } });
      // Redirecting to AdminPricing page
      // window.location.href = '/admin-pricing';
    } catch (error) {
      console.error('Error adding Price Plan:', error.response.data);
      alert('Sorry, an error occurred while adding the price plan.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Add New Price Plan</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
            <label>Block:</label>
              <input
                type="text"
                name="block"
                value={formData.block}
                onChange={handleChange}
                placeholder="Block"
             />
              <label>Category:</label>
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="Enter Plot Size"
                required
             />
              <label>Cost of Land:</label>
              <input
                type="number"
                name="costOfLand"
                value={formData.costOfLand}
                onChange={handleChange}
                placeholder="Cost of Land"
                required
              />
              <label>Down Payment:</label>
              <input
                type="number"
                name="downPayment"
                value={formData.downPayment}
                onChange={handleChange}
                placeholder="Down Payment"
                required
              />
              <label>Confirmation:</label>
              <input
                type="number"
                name="confirmation"
                value={formData.confirmation}
                onChange={handleChange}
                placeholder="Confirmation"
              />
              <label>Monthly Installments:</label>
              <input
                type="number"
                name="monthlyInstallments"
                value={formData.monthlyInstallments}
                onChange={handleChange}
                placeholder="Monthly Installments"
              />
              <label>Bi-Annual Installments:</label>
              <input
                type="number"
                name="biAnnualInstallments"
                value={formData.biAnnualInstallments}
                onChange={handleChange}
                placeholder="Bi-Annual Installments"
              />
              <label>Annual Installments:</label>
              <input
                type="number"
                name="annualInstallments"
                value={formData.annualInstallments}
                onChange={handleChange}
                placeholder="Annual Installments"
              />
              <label>Upon Ballot:</label>
              <input
                type="number"
                name="uponBallot"
                value={formData.uponBallot}
                onChange={handleChange}
                placeholder="Upon Ballot"
              />
              <label>Upon Possession:</label>
              <input
                type="number"
                name="uponPossession"
                value={formData.uponPossession}
                onChange={handleChange}
                placeholder="Upon Possession"
              />
               <label>Development Charges:</label>
              <input
                type="number"
                name="developmentcharges"
                value={formData.developmentcharges}
                onChange={handleChange}
                placeholder="Development Charges"
              />
               <label>Miscellaneous:</label>
              <input
                type="number"
                name="miscellaneous"
                value={formData.miscellaneous}
                onChange={handleChange}
                placeholder="Miscellaneous"
              />
              {/* Add input fields for other Price Plan attributes */}
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPricePlan;
