import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import '../../PannelStyling/AdminNews.css';
import axios from 'axios';

const AdminHeadlines = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [headlines, setHeadlines] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
          setError(true); 
          return;
        }

        // Fetch headlines from the API
        const fetchHeadlines = async () => {
            try {
                const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_headlines'); 
                console.log(response.data);
                setHeadlines(response.data.reverse()); 
            } catch (error) {
                console.error('Error fetching headlines:', error);
                setError(true); // Set error to true if there's an error fetching headlines
            }
        };

        fetchHeadlines();

    }, [email]);


    const handleRemoveHeadline = async (id) => {
      // Ask for confirmation before deleting the headline
      const confirmDelete = window.confirm('Are you sure you want to delete this headline?');
      if (!confirmDelete) {
          return; // If user cancels, do nothing
      }
  
      try {
          // Send DELETE request to delete the headline
          await axios.delete(`https://d1server.d1capitalparkcity.com/delete_headline/${id}`);
  
          // After successful removal, fetch and update the headlines
          const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_headlines');
          setHeadlines(response.data.reverse());
      } catch (error) {
          console.error('Error removing headline:', error);
      }
  };


    const handleAddNewHeadline = () => {
        navigate('/addnewheadline', { state: { email, userRole, userPermissions } });
    };
    const handleEditHeadline = (id) => {
      navigate('/editheadline', { state: { id, email, userRole, userPermissions } });
  };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                         email={email || ''} 
                         userRole={userRole || ''} 
                         userPermissions={userPermissions || []} 
                       /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">LATEST HEADLINES</h1>
                        <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddNewHeadline}>Add New Headline</button>
                        </div>
                        <div className="adminnews-events-grid">
                        {headlines.map((headline, index) => (
                                   <div key={index} className="news-event-card">
                                     <h2>Type: {headline.Type}</h2>
                                       <h4>{headline.Title}</h4>
                                       <h6>{headline.HeadlineDetail}</h6>
                                       <div className='News-Buttons'>
                                    <button className="btn42-43 btn-42" onClick={() => handleEditHeadline(headline._id)}>Edit</button>
                                    <button className="btn42-43 btn-42" onClick={() => handleRemoveHeadline(headline._id)}>Delete</button>
                                    </div>
                                   </div>
                                   
                               ))}
                                  
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdminHeadlines;
