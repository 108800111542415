import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/FloatingWhatsapp.css';
import Whatsapp from '../assets/whatsapp.png';

const FloatingWhatsapp = () => {
  const [whatsappNumber, setWhatsappNumber] = useState('');

  useEffect(() => {
    const fetchWhatsAppNumber = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_social_media');
        const whatsappEntry = response.data.find(entry => entry.category.toLowerCase() === 'whatsapp');
        if (whatsappEntry) {
          setWhatsappNumber(whatsappEntry.link);
        } else {
          console.log('WhatsApp number not found in social media entries.');
        }
      } catch (error) {
        console.error('Error fetching WhatsApp number:', error);
      }
    };

    fetchWhatsAppNumber();
  }, []);

  const openWhatsApp = () => {
    // Open WhatsApp with the provided number
    window.open(`${whatsappNumber}`, '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={openWhatsApp}>
      <img src={Whatsapp} alt="WhatsApp Icon" />
    </div>
  );
};

export default FloatingWhatsapp;
