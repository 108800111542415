import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/OurLocation.css';
import wonderlocation from '../../assets/wonderlocation.gif';

const OurLocation = () => {
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_wonder_locations'); // Adjust endpoint as per your backend
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching wonder locations:', error);
        setError(true);
      }
    };

    fetchLocations();
  }, []);

  return (
    <div className='ourlocation-container'>
      {error ? (
        <p className='location-para'>Failed to load wonder locations.</p>
      ) : (
        <div>
          {locations.map((location, index) => (
            <div key={index}>
              <p className='location-para'>{location.description}</p>
              <img className='wonderlocation' src={location.image} alt='location' />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OurLocation;
