import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const Button = () => {


  return (
    <div>
      <Link to="/contact-us">
      <button className='btn41-43 btn-43' >Submit Your Interest</button></Link>
    </div>
  )
}

export default Button;