import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Import CSS for styling

const ContactDetailEdit = () => {
    const location = useLocation();
    const { id, email, userRole,userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [heading, setHeading] = useState('');
    const [details, setDetails] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
            return;
        }
        else if(id) {
            fetchContactDetail(id);
        }
    }, [id]);

    const fetchContactDetail = async (id) => {
        try {
            const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_contact_detail/${id}`);
            const { heading, details } = response.data;
            setHeading(heading);
            setDetails(details);
        } catch (error) {
            console.error('Error fetching contact detail:', error);
            setError(true);
        }
    };

    const handleAddContactDetail = async () => {
        try {
            const newContactDetail = { heading, details };
            if (id) {
                await axios.put(`https://d1server.d1capitalparkcity.com/update_contact_detail/${id}`, newContactDetail);
                alert("Details Updated Succefully");
            } else {
                await axios.post('/save_contact_detail', newContactDetail);
                alert("Details Added Succefully");
            }
            navigate('/admincontactdetail', { state: { email, userRole, userPermissions } }); // Redirect after successful addition or update
        } catch (error) {
            console.error('Error adding/updating contact detail:', error);
            // Handle error as needed
        }
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                     email={email || ''} 
                     userRole={userRole || ''} 
                     userPermissions={userPermissions || []} 
                 /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">{id ? 'Edit Contact Detail' : 'Add New Contact Detail'}</h1>
                        <div className='AddNews-Form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <label htmlFor='heading'>Heading</label>
                                    <input
                                        type='text'
                                        id='heading'
                                        value={heading}
                                        onChange={(e) => setHeading(e.target.value)}
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='details'>Details</label>
                                    <textarea
                                        id='details'
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                        rows='4'
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <button onClick={handleAddContactDetail} >
                                        {id ? 'Update Contact Detail' : 'Add Contact Detail'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactDetailEdit;
