import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const EditGallery = () => {
  const location = useLocation();
  const { email, id, userRole, userPermissions } = location.state || {};
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    link: '',
    title: '',
    description: '',
    index:'',
  });

  useEffect(() => {
    const fetchGalleryItem = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_gallery/${id}`);
        const { link, title, description, index } = response.data;
        setFormData({ link, title, description, index });
      } catch (error) {
        console.error('Error fetching gallery item:', error);
        setError(true);
      }
    };

    if (!email) {
      setError(true);
    } else {
      fetchGalleryItem();
    }
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_gallery/${id}`, formData);
      alert('Gallery item updated successfully');
      navigate('/admingallery', { state: { email, userRole, userPermissions } }); // Adjust the redirect route as necessary
    } catch (error) {
      console.error('Error updating gallery item:', error);
      alert(error.response.data.message); 
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
          Error 404: Page Not Found
        </h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Edit Gallery Item</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Image Link:</label>
              <input
                type="text"
                name="link"
                value={formData.link}
                onChange={handleChange}
                placeholder="Image URL"
                required
              />
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Image Title"
                required
              />
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Image Description"
                required
              />
              <label>Index:</label>
              <input
                type="number"
                name="index"
                value={formData.index}
                onChange={handleChange}
                placeholder="Index Number"
                required
              />
              <button type="submit">Update Gallery Item</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditGallery;
