import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Import CSS for styling

const EditProjectAmenities = () => {
    const location = useLocation();
    const { id, email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
            return;
        }
        else if (id) {
            fetchAmenity(id);
        }
    }, [id]);

    const fetchAmenity = async (id) => {
        try {
            const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_project_amenity/${id}`);
            const { image, title, description } = response.data;
            setImage(image);
            setTitle(title);
            setDescription(description);
        } catch (error) {
            console.error('Error fetching amenity:', error);
            setError(true);
        }
    };

    const handleEditAmenity = async () => {
        try {
            const updatedAmenity = { image, title, description };
            if (id) {
                await axios.put(`https://d1server.d1capitalparkcity.com/update_project_amenity/${id}`, updatedAmenity);
            alert("Project Amenity Updated Successfully");
            } else {
                await axios.post(`https://d1server.d1capitalparkcity.com/save_project_amenity`, updatedAmenity);
            alert("Project Amenity Added Successfully");
            }
            
            navigate('/projectamenitiesadmin', { state: { email, userRole, userPermissions } }); // Redirect after successful update
        } catch (error) {
            console.error('Error updating amenity:', error);
            // Handle error as needed
        }
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                      email={email || ''} 
                      userRole={userRole || ''} 
                      userPermissions={userPermissions || []} 
                    /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">{id ? 'Edit Project Amenity' : 'Add New Project Amenity'}</h1>
                        <div className='AddNews-Form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <label htmlFor='image'>Image URL</label>
                                    <input
                                        type='text'
                                        id='image'
                                        value={image}
                                        onChange={(e) => setImage(e.target.value)}
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='title'>Title</label>
                                    <input
                                        type='text'
                                        id='title'
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='description'>Description</label>
                                    <textarea
                                        id='description'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        rows='4'
                                        required
                                        className='form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <button onClick={handleEditAmenity}>
                                        {id ? 'Update Project Amenity' : 'Add Project Amenity'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditProjectAmenities;
