import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminListData.css'; // Import the external CSS file
import { Audio } from "react-loader-spinner"; // Import loader spinner component

const AdminListData = ({ email }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetchalldata');
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setContactData(response.data);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else {
          setError("Sorry for the inconvenience! Technical issues occurred. Please try again later!");
          console.log(error);
        }
      } finally {
        setIsLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchData();
  }, []); // Fetch data only once when component mounts

  const getRoleString = (role) => {
    switch(role) {
      case 1:
        return 'Super Admin';
      case 2:
        return 'Admin';
      case 3:
        return 'Moderator';
      case 4:
        return 'Content Editor';
      case 5:
        return 'Customer Support';
      default:
        return 'Not Assigned';
    }
  };

  return (
    <div className="AdminListData-Container">
      <div className="AdminListData-Main">
        <div className="AdminListData-Content">
          <h2 className="h1stake">Admins Details</h2>
          {isLoading ? (
            <div className="loader">
              <Audio color="#FFD700" height={80} width={80} />
            </div>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <table className="table table-striped table-dark ">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {contactData.map(contact => (
                  <tr key={contact._id}>
                    <td>{contact.name}</td>
                    <td>{contact.email}</td>
                    <td>{contact.pnumber}</td>
                    <td>{getRoleString(contact.role)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminListData;
