import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '..//AdminHeader';
import Sidebar from '../AdminSidebar';
import '../../PannelStyling/AddNews.css'; // Reuse styling from AddNews.css

const EditMemberForm = () => {
  const location = useLocation();
  const { id, email, index, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({});
  const [image, setImage] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
    } else {
      fetchMemberData(); // Fetch data
    }
  }, [email]);

  const fetchMemberData = async () => {
    try {
      const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_aboutus/${id}`);
      setFormData(response.data);
    } catch (error) {
      console.error('Error fetching member:', error);
      setError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    if (!file) {
      // If no file is selected, set the image state to formData.profile
      setImage(formData.profile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('designation', formData.designation);
      formDataToSend.append('index', formData.index); // Include index value in the form data
      if (profileImage) {
        formDataToSend.append('profile', profileImage);
      } else if (!profileImage && image) {
        // If no profileImage selected but image state is set, append it to formDataToSend
        formDataToSend.append('profile', image);
      }
      
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_aboutus/${id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Member updated successfully:', response.data);
      alert('Member Updated Successfully');
      navigate('/adminaboutus', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error updating member:', error);
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1>Error 404: Page Not Found</h1>
      ) : (
        <div>
           <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Edit Member</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Name:</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} />
              <label>Designation:</label>
              <input type="text" name="designation" value={formData.designation} onChange={handleChange} />
              <label>Profile Image:</label>
              <input type="file" name="profile" onChange={handleImageChange} />
              {/* Input field for index value */}
              <label>Index:</label>
              <input type="text" name="index" value={formData.index} onChange={handleChange} />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditMemberForm;
