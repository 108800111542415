import React from 'react'
import './SignupDetail.css'

const SignupDetail = () => {
  return (
    <div className='Signupdetail-Container'>
    <h3>ہے ابتدا ایک نئی دنیا کی</h3>
          <h2>Welcome To D1 Capital Park City Member Web Portal</h2>
          <h5>Start your journey into a new world with us! By registering your account today, you'll unlock a wealth of online facilities designed to enhance your experience with us.</h5>
        </div>
  )
}

export default SignupDetail
