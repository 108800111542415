import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';

const EditDevelopmentUpdate = () => {
  const location = useLocation();
  const { id, email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    type: '',
    image: '',
    videoSrc: '',
    heading: '',
    details: ''
  });

  useEffect(() => {
    const fetchDevelopmentUpdate = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_development_update/${id}`);
        const { type, image, videoSrc, heading, details } = response.data;
        setFormData({ type, image, videoSrc, heading, details });
        
      } catch (error) {
        console.error('Error fetching development update:', error);
        setError(true);
      }
    };

    if (!email) {
      setError(true); // Set error to true if email is not provided
    } else {
      fetchDevelopmentUpdate();
    }
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_development_update/${id}`, formData);
      console.log('Development update updated successfully:', response.data);
      alert(response.data.message);
      navigate('/admindevelopment', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error updating development update:', error);
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Update Development Update</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <select name="type" value={formData.type} onChange={handleChange} required>
                <option value="">Select type</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
              {formData.type === 'image' && (
                <>
                  <label>Image URL:</label>
                  <input
                    type="text"
                    name="image"
                    value={formData.image}
                    onChange={handleChange}
                    placeholder="Image URL"
                  />
                </>
              )}
              {formData.type === 'video' && (
                <>
                  <label>Video URL:</label>
                  <input
                    type="text"
                    name="videoSrc"
                    value={formData.videoSrc}
                    onChange={handleChange}
                    placeholder="Video URL"
                  />
                </>
              )}
              <label>Heading:</label>
              <input
                type="text"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
                placeholder="Heading"
              />
              <label>Details:</label>
              <textarea
                name="details"
                value={formData.details}
                onChange={handleChange}
                placeholder="Details"
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDevelopmentUpdate;
