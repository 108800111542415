import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const EditFaqs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    category: '',
    question: '',
    answer: '',
  });

  useEffect(() => {
    const fetchFAQ = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_faq/${id}`);
        const { category, question, answer } = response.data;
        setFormData({ category, question, answer });
      } catch (error) {
        console.error('Error fetching FAQ:', error);
        setError(true);
      }
    };

    if (!email) {
      setError(true); // Set error to true if email is not provided
    } else {
      fetchFAQ();
    }
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_faq/${id}`, formData);
      console.log('FAQ updated successfully:', response.data);
      alert(response.data.message);
      navigate('/adminfaqs', { state: { email, userRole, userPermissions } }); // Adjust the redirect route as necessary

    } catch (error) {
      console.error('Error updating FAQ:', error);
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake" >Update FAQ</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Category:</label>
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder="FAQ Category"
                required
              />
              <label>Question:</label>
              <input
                type="text"
                name="question"
                value={formData.question}
                onChange={handleChange}
                placeholder="FAQ Question"
                required
              />
              <label>Answer:</label>
              <textarea
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                placeholder="FAQ Answer"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditFaqs;
