import React, { useState, useEffect } from 'react';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar'; // Import Sidebar if needed
import { useLocation, useNavigate } from 'react-router-dom';
import Salespartner from '../../../../JsonDoc/Salespartner.json';
import '../../PannelStyling/AdminNews.css';
import '../../PannelStyling/AdminSalesPartnerStyling.css';

const AdminSalesPartner = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [partners, setPartners] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    } else {
      // Simulated fetching data
      const fetchData = async () => {
        try {
            const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_partner');
            if (!response.ok) {
              throw new Error('Sorry for the Inconvenance, Technical Issuee Occured!');
            }
            const data = await response.json();
        // setPartners(Salespartner); //From json file  
           setPartners(data.reverse());  //From DB
        } catch (error) {
          console.error('Error fetching partner data:', error);
          setError(true);
        }
      };

      fetchData();
    }
  }, [email]); // Fetch data when email changes



  const handleRemovePartner= async (id) => {
    // Ask for confirmation before deleting the article
    const confirmDelete = window.confirm('Are you sure you want to delete this Partner?');
    if (!confirmDelete) {
        return; // If user cancels, do nothing
    }

    try {
        // Send DELETE request to delete the article
        await fetch(`https://d1server.d1capitalparkcity.com/delete_partner/${id}`, {
            method: 'DELETE',
        });

        // After successful removal, fetch and update the news events
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_partner');
        const data = await response.json();
        setPartners(data.reverse());
    } catch (error) {
        console.error('Error removing article:', error);
        throw new Error('Sorry for the Inconvenance, Technical Issuee Occured!');
    }
};


  const handleAddNewPartner = () => {
    navigate('/addsalespartner', { state: { email, userRole, userPermissions } });
};
const handleEditNewPartner = (id) => {
  navigate('/editsalespartner', { state: { id, email, userRole, userPermissions }});
};


  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Authorized Sales Partner</h1>
            <p style={{ color: 'red' }}><b>Note: Image Should be uploaded at Image kit and size should be (540x540)</b></p>

            <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddNewPartner}>Add New Sales Partner</button>
            </div>
            <div className="partners-grid">
              {partners.map((partner, index) => (
                <div key={index} className="partner-card">
                  <img src={partner.logo} alt={partner.name} className="partner-logo"/>
                  <h2>{partner.name}</h2>
                  <p>{partner.description}</p>
                  <div className='News-Buttons'>
                    <button className="btn42-43 btn-42" onClick={() => handleEditNewPartner(partner._id)}>Edit</button>
                    <button className="btn42-43 btn-42" onClick={() => handleRemovePartner(partner._id)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSalesPartner;
