import React, { Suspense, useEffect, useState}from 'react';
import '../styles/Gallery.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import phata from '../assets/phata.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import ReactLoader from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';




const Gallery = () => {

  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_gallery'); 
        if (!response.ok) {
          throw new Error('Sorry for the inconvenence.Technical issue occured!');
        }
        const data = await response.json();
        setGalleryData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching gallery data:', error);
        setLoading(false);
      }
    };

    fetchGalleryData();
      
    
  }, []);



  

  return (
    <div>
       <Helmet>
        <title>D1 Capital Park City | Gallery</title>
        <meta name="description" content="D1 Capital Park City (The City of Innovations & Tourism)."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/gallery'/>
      </Helmet>
    <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoader} alt="Loading..." /></div>}>
      <div>
        <Header/>
        <div className="phata">
          <img src={phata} alt="Phata" loading="lazy" />
        </div>
      </div>

   <h1 className='h1stake'>OUR GALLERY</h1>
   <div className='Gallery'>
          {loading ? (
            <div>Loading...</div>
          ) : (
            galleryData.map((item) => (
              <div key={item._id} className="image-container">
                <img  src={item.link}  alt={item.title} onLoad={(e) => { e.target.style.opacity = 1; }}/>
                <div className="image-info">
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            ))
          )}
        </div>

<Whatsapp/>
      <div>
        <Footer/>
       
      </div>
      </Suspense>
 
    </div>
  );
};

export default Gallery;
