import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactUsDashboard.css';
import CanvasJSReact from '@canvasjs/react-charts';
import { Audio } from "react-loader-spinner"; // Import loader spinner component

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ContactUsDashboard = () => {
  const [error, setError] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_contact_submissions');
      if (response.status === 200) {
        console.log('Fetched data:', response.data); // Log fetched data for debugging
        setContactData(response.data);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const getChartData = () => {
    // Prepare data for CanvasJS
    const monthsData = {}; // Object to hold lead counts per month

    // Loop through contactData to aggregate lead counts by month
    contactData.forEach(contact => {
      const date = new Date(contact.Time);
      const monthName = date.toLocaleString('en-us', { month: 'short' }); // Get month name (e.g., Jan, Feb, etc.)
      const yearMonthKey = `${date.getFullYear()}-${date.getMonth()}`; // Key format: yyyy-m (e.g., 2024-5 for May 2024)

      if (!monthsData[yearMonthKey]) {
        monthsData[yearMonthKey] = {
          month: monthName,
          leads: 0
        };
      }

      monthsData[yearMonthKey].leads++; // Increment lead count for the month
    });

    console.log('Months data:', monthsData); // Log monthsData for debugging

    // Transform monthsData into CanvasJS dataPoints array
    const dataPoints = Object.values(monthsData).map(item => ({
      label: item.month, // Use item.month to display month name directly
      y: item.leads
    }));

    console.log('Data points:', dataPoints); // Log dataPoints for debugging

    // Check if there are no data points to display
    if (dataPoints.length === 0) {
      return {
        animationEnabled: true,
        theme: "dark2",
        title: {
          text: "Number of Leads by Month"
        },
        axisX: {
          title: "Months",
          interval: 1, // Display every label
          labelFontSize: 12, // Adjust label font size if needed
          valueFormatString: "MMM",
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          }
        },
        axisY: {
          title: "Number of Leads",
          includeZero: false,
          interval: 1, // Y-axis step size
          valueFormatString: "###0",
          crosshair: {
            enabled: true,
            snapToDataPoint: true
          }
        },
        data: [] // Empty data array to prevent chart rendering if no data
      };
    }

    return {
      animationEnabled: true,
      theme: "dark2",
      backgroundColor: "#000",
      title: {
        text: "Number of Leads by Month"
      },
      axisX: {
        title: "Months",
        interval: 1, // Display every label
        labelFontSize: 12,
        crosshair: {
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        title: "Number of Leads",
        includeZero: false,
        interval: 1, // Y-axis step size
        valueFormatString: "###0",
        crosshair: {
          enabled: true,
          snapToDataPoint: true
        }
      },
      data: [{
        type: "column", // Change type to "column" for vertical bars
        color: "#E5B80B", // Golden color
        indexLabel: "{y}", // Display y value on top of each bar
        indexLabelFontColor: "#333", // Font color for index label
        indexLabelPlacement: "inside", // Position of index label
        indexLabelFontSize: 14, // Font size of index label
        BorderRadius: 10, // Border radius for all sides of bars
        dataPoints: dataPoints
      }]
    };
  };

  return (
    <div className='Contactus-leads'>
      <h2 className='h1stake'>Contact us Leads</h2>
      {isLoading ? (
        <div className="loader">
          <Audio color="#FFD700" height={80} width={80} />
        </div>
      ) : error ? (
        <p className="error-message">Error fetching data</p>
      ) : (
        <div className='chart-container'>
          <CanvasJSChart options={getChartData()} />
        </div>
      )}
    </div>
  );
};

export default ContactUsDashboard;
