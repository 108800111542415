import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddNewHeadline = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    Type: '', // Adding the Type field
    Title: '',
    HeadlineDetail: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]); // Fetch data when email changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_headline', formData);
      console.log('Headline added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminheadline', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error adding Headline:', error.response.data);
      alert('Sorry, an error occurred while adding the headline.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
        <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />           <h1 className="h1stake">Add New Headline</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <select
                name="Type"
                value={formData.Type}
                onChange={handleChange}
                required
              >
                <option value="">Select Type</option>
                <option value="News">News</option>
                <option value="DevelopmentUpdate">Development Update</option>
              </select>
              <label>Title:</label>
              <input
                type="text"
                name="Title"
                value={formData.Title}
                onChange={handleChange}
                placeholder="Headline Title"
                required
              />
              <label>Detail:</label>
              <textarea
                name="HeadlineDetail"
                value={formData.HeadlineDetail}
                onChange={handleChange}
                placeholder="Headline Detail"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewHeadline;
