import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddFaqs = () => {
  const location = useLocation();
  const { email,userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    category: '',
    question: '',
    answer: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]); // Fetch data when email changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_faq', JSON.stringify(formData), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('FAQ added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminfaqs', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error adding FAQ:', error.response.data);
      alert('Sorry, an error occurred while adding the FAQ.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Add New FAQ</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Category:</label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select Category</option>
                <option value="General">General</option>
                <option value="Payment">Payment</option>
                <option value="Booking">Booking</option>
              </select>
              <label>Question:</label>
              <input
                type="text"
                name="question"
                value={formData.question}
                onChange={handleChange}
                placeholder="Question"
                required
              />
              <label>Answer:</label>
              <textarea
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                placeholder="Answer"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddFaqs;
