import React, { useState, useEffect, Suspense, lazy, useMemo } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import phata from '../assets/phata.gif';
import Footer from '../components/Footer';
import '../styles/Contactus.css';
import ReactLoading from '../assets/React-Loading.gif';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const LazyFloatingWhatsapp = lazy(() => import('../components/FloatingWhatsapp'));

const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [contactDetail, setContactDetail] = useState([]);


  const location = useLocation();
  const { selectedPlotSize } = location.state || {};

  const messageTemplate = useMemo(() => {
    if (!selectedPlotSize) return '';
    return `Selected Plot Size: ${selectedPlotSize}`;
  }, [selectedPlotSize]);

  useEffect(() => {
    const fetchSocialMediaLinks = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_social_media');
        setSocialMediaLinks(response.data);
      } catch (error) {
        console.error('Error fetching social media links:', error);
      }
    };
    const fetchContactDetail = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_contact_details');
        setContactDetail(response.data);
      } catch (error) {
        console.error('Error fetching social media links:', error);
      }
    };

    fetchContactDetail();
    fetchSocialMediaLinks();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const Message = message + '\n' + messageTemplate;

    const currentDate = new Date();
    const timestamp = currentDate.toLocaleString();
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Phone number validation regex (for Pakistani numbers)
    const phoneRegex = /^(?:\+?92|\(?0?\d{2}\)?)\d{9,10}$/;

    // Check if email and phone number are in correct format
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address');
      return;
    }

    if (!phoneRegex.test(phonenumber)) {
      alert('Please enter a your valid phone/mobile number.');
      return;
    }

    const formData = {
      Name: name,
      Email: email,
      PhoneNumber: phonenumber,
      Message: Message,
      Time: timestamp
    };
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_contact_submission', formData);

      if (response.status === 201) {
        alert('Your Interest has been Submitted Successfully! Our Team will contact you Shortly! ');
        setName('');
        setEmail('');
        setPhonenumber('');
        setMessage('');

      } else {
        alert('We are sorry! We could not process your request at the moment! Try again Later!.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Sorry! We are facing some techinical isssues for the moment!');
    }
  };

  return (
    <div className="contactus-container">
      <Helmet>
        <title>D1 Capital Park City | Contact Us</title>
        <meta name="description" content="Our helpline is always open to receive any inquiry or feedback." />
        <link rel='canonical' href='https://d1capitalparkcity.com/contact-us' />
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor: "#000", width: "100%", height: "100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
        <Header />
        <div className="phata">
          <img src={phata} alt="Phata" />
        </div>
        <div className="contactus-content">
          <h1 className="contacth1stake">Contact Us </h1>
          <div className="contact-form-container">
            <div className="Contactus-form">
              <form onSubmit={handleSubmit}>
                <label className="animated-label">
                  Name:
                  <input
                    type="text"
                    placeholder="Please Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    maxLength={40}
                    required
                  />
                </label>
                <br />
                <label className="animated-label">
                  Email:
                  <input
                    type="email"
                    placeholder="Please Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
                <br />
                <label className="animated-label">
                  Phone Number:
                  <input
                    type="number"
                    placeholder="Please Enter your phone/mobile number"
                    value={phonenumber}
                    onChange={(e) => setPhonenumber(e.target.value)}
                    required
                  />
                </label>

                <br />
                <label className="animated-label">
                  Message:
                  <textarea
                    value={message}
                    placeholder="Enter your message(Optional)"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </label>
                <br />
                {/* <label className="animated-label">
                Pre-Filled Message:
                <textarea
                  value={messageTemplate}
                  placeholder="No Message to Shown."
                  readOnly={true}
                style={{ height: 'auto', lineHeight: 'normal', resize: 'vertical' }}
                />
              </label>
  <br />*/}
                <button className='btn41-43 btn-43' type="submit">Submit Your Interest</button>
                <br /><br />
              </form>
            </div>
            <div className="additional-content">
            <div>
                    {contactDetail.map((detail, index) => (
                      <div key={index}>
                        {detail.heading && (
                          <>
                            <h4>{detail.heading}</h4>
                            <p>{detail.details}</p>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end social-icons">
              {socialMediaLinks.map((link, index) => (
  <a key={index} href={link.link} className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
    {link.category.toLowerCase() === 'mail' ? (
      <i className="fas fa-envelope"></i>
    ) : link.category.toLowerCase() === 'facebook' ? (
      <i className="fab fa-facebook-f"></i>
    ) : (
      <i className={`fab fa-${link.category.toLowerCase()}`}></i>
    )}
  </a>
))}

              </div>
            </div>
          </div>
        </div>
        <LazyFloatingWhatsapp />
        <Footer />
      </Suspense>
    </div>
  );
};

export default Contactus;
