import React from 'react';
import AdminHeader from './components/AdminHeader'; // assuming this component exists

const ErrorPage403 = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div style={{ backgroundColor: '#333', color: '#fff', padding: '10px' }}>
        <AdminHeader />
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h2>Error 403: You are Restricted to go back!</h2>
      </div>
    </div>
  );
};

export default ErrorPage403;
