import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner'; // Import loader spinner component
import '../../PannelStyling/AdminNews.css'; // Import existing CSS for styling
import '../../../../styles/Ourteams.css'; // Import existing CSS for styling

const AdminLocationMap = () => {
  const location = useLocation();
  const { email, userRole, userPermissions} = location.state || {};
  const [error, setError] = useState(false);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
    } else {
      // Fetch data when email is provided
      fetchLocationsData(); // Fetch data
    }
  }, [email]); // Fetch data when email changes

  const fetchLocationsData = async () => {
    try {
      // Make a request to your backend API to fetch data
      const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_understandinglocations');
      setLocations(response.data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true); // Set error to true if there's an error fetching data
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleRemoveLocation = async (id) => {
    // Ask for confirmation before deleting the location
    const confirmDelete = window.confirm('Are you sure you want to delete this Location?');
    if (!confirmDelete) {
      return; // If user cancels, do nothing
    }

    try {
      // Send DELETE request to delete the location
      await axios.delete(`https://d1server.d1capitalparkcity.com/delete_understandinglocation/${id}`);

      // After successful removal, fetch and update the locations
      fetchLocationsData();
    } catch (error) {
      console.error('Error removing location:', error);
    }
  };

  const handleAddNewLocation = () => {
    navigate('/addeditunderstandinglocation', { state: { email, userRole, userPermissions } });
  };

  const handleEditLocation = (id) => {
    navigate('/addeditunderstandinglocation', { state: { id, email, userRole, userPermissions } });
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Understanding Locations</h1>
            <p style={{ color: 'red' }}><b>Note: Image size should be (1571x1111)</b></p>
            <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddNewLocation}>Add New Location</button>
            </div>
            {/* Render fetched locations data */}
            <div className='adminnews-events-grid' >
              {isLoading ? (
                <div className="loading-overlay">
                  <Audio color="#FFD700" height={80} width={80} />
                </div>
              ) : (
                locations.map(location => (
                  <div className='Member1' key={location._id} style={{ border: '1px solid black', backgroundImage: 'none' }}>
                    <div className='Member-image'>
                    {location.profile && location.profile.data && (
                        <img src={`data:image/jpeg;base64,${location.profile.data}`} alt='Location' loading='lazy' />
                      )}                    </div>
                    <div className='Member-intro'>
                      <h1>{location.link}</h1>
                      <div className='News-Buttons'>
                        <button className="btn42-43 btn-42" onClick={() => handleEditLocation(location._id)}>Edit</button>
                        <button className="btn42-43 btn-42" onClick={() => handleRemoveLocation(location._id)}>Delete</button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminLocationMap;
