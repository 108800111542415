import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Header from '../components/AdminHeader.js';
import Sidebar from '../components/AdminSidebar.js';
import '../PannelStyling/ChangePassword.css'; // Import CSS file for styling



function SuccessMessage({ message }) {
  return (
    <div class="alert alert-success" role="alert">
      {message}
    </div>
  );
}


const ChangePassword = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  useEffect(() => {
    if (!email) {
      setErrorMessage('Error 404: Page Not Found');
      return;
    }
  }, [location.search])


  const handleFocus =async()=>{
   setErrorMessage('');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('New Password and Confirm Password do not match');
      return;
    }
    else if(newPassword==oldPassword){
      setErrorMessage('New Password must be different from old password');
      return;
    }
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/change-admin-password', {
        email,
        oldPassword,
        newPassword
      });
      
      setSuccessMessage('Password Changed successfully!');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        setSuccessMessage(''); 
      }, 2000); // 3000 milliseconds (3 seconds) delay before navigation
    
      
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // Adjusted to error.response.data.error to match the backend
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Failed to update password');
      }
      console.error('Error updating password:', error);
    }
  };



  return (
    <div>
      <Header />
      <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
      <div className="profile-container">
        <h1>Change Password</h1>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <SuccessMessage message={successMessage} />}
        <form onSubmit={handleSubmit}>
          <div className="profile-heading">Old Password</div>
          <div className="Change-password-item">
            <input
              className="Change-password-value"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              onFocus={handleFocus}
              placeholder="Enter Old Password"
              required
            />
          </div>

          <div className="profile-heading">New Password</div>
          <div className="Change-password-item">
            <input
              className="Change-password-value"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={handleFocus}
              placeholder="Enter New Password"
              required
            />
          </div>
          <div className="Change-password-item">
            <input
              className="Change-password-value"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={handleFocus}
              placeholder="Re-Enter New Password"
              required
            />
          </div>
          
          <button type="submit" className="btn42-43 btn-42">
            Update New Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
