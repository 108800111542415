import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../AdminHeader.js';
import Sidebar from '../../AdminSidebar.js';
import axios from 'axios';
import '../../../PannelStyling/AddNews.css';

const AddOurGroup = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    Type: '',
    Title: '',
    Name: '',
    Message: '',
    ProfileImage: '',
    Experience:'',
    index: '' 
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!email) {
      setError(true);
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_ourgroup', formData);
      console.log('New member added successfully:', response.data);
      alert(response.data.message);
      navigate('/adminourgroup', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error adding new member:', error.response.data);
      alert(error.response.data.message);
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
         <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />           <h1 className="h1stake">Add New Member</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <select
               name="Type"
               value={formData.Type}
               onChange={handleChange}
               required
             >
               <option value="">Select Type</option>
               <option value="Chairman">Chairman</option>
               <option value="CEO">CEO</option>
               <option value="President">President</option>
               <option value="Director">Director</option>
             </select>
              <label>Title:</label>
              <input
                type="text"
                name="Title"
                value={formData.Title}
                onChange={handleChange}
                placeholder="Title"
                required
              />
              <label>Name:</label>
              <input
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                placeholder="Name"
                required
              />
              <label>Message:</label>
              <input
                type="text"
                name="Message"
                value={formData.Message}
                onChange={handleChange}
                placeholder="Message"
                required
              />
              <label>Profile Image:</label>
              <input
                type="text"
                name="ProfileImage"
                value={formData.ProfileImage}
                onChange={handleChange}
                placeholder="Profile Image"
                required
              />
              <label>Experience:</label>
              <input
                type="text"
                name="Experience"
                value={formData.Experience}
                onChange={handleChange}
                placeholder="Experience"
                required
              />
                <label>Index:</label>
              <input
                type="text"
                name="index"
                value={formData.index}
                onChange={handleChange}
                placeholder="Index"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddOurGroup;
