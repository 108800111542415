import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/AdminHeader';
import Sidebar from '../components/AdminSidebar';
import axios from 'axios';
import '../PannelStyling/AddNews.css'; // Import CSS file with styling

const AddAdmin = () => {
    const location = useLocation();
    const { email,userRole, userPermissions } = location.state || {};
    const[confirmPassword, SetConfirmPassord]= useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        pnumber: '',
        password: '',
        role: '',
    });
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleConfirmPasswordChange = (e) => {
        const { value } = e.target;
        SetConfirmPassord(value);
    };

    useEffect(() => {
        if (!email) {
          setError(true); // Set error to true if email is not provided
        } 
        
      }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(formData.password!==confirmPassword){
                alert("Passwrod and Confirm Password must be same");
            }
            else if(formData.password==confirmPassword){
            const response = await axios.post('https://d1server.d1capitalparkcity.com/registeradmin', formData);
            console.log(response.data);
            navigate('/adminlist', { state: { email, userRole, userPermissions } }); // Redirect to dashboard after successful registration
            }
        } catch (error) {
            
            console.error('Error registering admin:', error);
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message);
            } else {
                alert("Error Adding New Admin! Sorry for the inconvenance, Technical Issue occured");
            }
        }
    };

    return (
        <div className='AddNews-Container'> {/* Apply same class name as in AddPopup */}
            <Header />
            {error ? (
         <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
        ) : (
                <div>
            <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />  
                        <h1 className="h1stake">Add Admin</h1>
                        <div className='AddNews-Form'> 
                        <form onSubmit={handleSubmit}>
                            <label>Admin Name::</label>
                            <input type="text" name="name" value={formData.name} placeholder="Name" onChange={handleChange} required />
                            <label>Email</label>
                            <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleChange} required />
                            <label>Phone Number:</label>
                            <input type="number" name="pnumber" value={formData.pnumber} placeholder="Phone Number" onChange={handleChange} required />
                            <label>Password:</label>
                            <input type="password" name="password" value={formData.password} placeholder="Password" onChange={handleChange} required />
                            <label>Confirm Password</label>
                            <input type="password" name="confirmPassword" value={confirmPassword} placeholder="Confirm Password" onChange={handleConfirmPasswordChange} required />

                            <label>Admin Role:</label>
                            <select name="role" value={formData.role} onChange={handleChange} required>
                                <option value="">Select Role</option>
                                <option value="1">Super Admin</option>
                                <option value="2">Admin</option>
                                <option value="3">Moderator</option>
                                <option value="4">Content Editor</option>
                                <option value="5">Customer Support</option>
                            </select>
                            <button type="submit">Add Admin</button>
                        </form>
                    </div>
                    </div>
            )}
        </div>
    );
};

export default AddAdmin;
