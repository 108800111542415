import React, { useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Make sure to adjust the path if necessary

const AddSocialMedia = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        category: '',
        link: '',
    });

    useEffect(() => {
        if (!email) {
            setError(true); // Set error to true if email is not provided
          } 
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData);
            const response = await axios.post('https://d1server.d1capitalparkcity.com/save_social_media', JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Social media entry added successfully:', response.data);
            alert(response.data.message);
            navigate('/adminsocialmedia', { state: { email, userRole, userPermissions } });
        } catch (error) {
            console.error('Error adding social media entry:', error.response.data);
            alert('Sorry, an error occurred while adding the social media entry.');
        }
    };

    return (
        <div className='AddNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div>
                    <Sidebar 
                      email={email || ''} 
                      userRole={userRole || ''} 
                      userPermissions={userPermissions || []} 
                    /> 
                    <h1 className="h1stake">Add New Social Media Entry</h1>
                    <div className='AddNews-Form'>
                        <form onSubmit={handleSubmit}>
                            <label>Category:</label>
                            <select
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Category</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Twitter">Twitter</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Whatsapp">Whatsapp</option>
                                <option value="Youtube">Youtube</option>
                                <option value="Mail">Mail</option>
                            </select>
                            <label>Link:</label>
                            <input
                                type="text"
                                name="link"
                                value={formData.link}
                                onChange={handleChange}
                                placeholder="Link"
                                required
                            />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddSocialMedia;
