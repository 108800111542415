import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const EditHeadline = () => {
  const location = useLocation();
  const { email, id, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    Type: '',
    Title: '',
    HeadlineDetail: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!email || !id) {
      setError(true); // Set error to true if email or id is not provided
      return;
    }
    // Fetch headline data by id
    const fetchHeadline = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_headline/${id}`);
        const headlineData = response.data;
        setFormData({
          Type: headlineData.Type,
          Title: headlineData.Title,
          HeadlineDetail: headlineData.HeadlineDetail,
        });
      } catch (error) {
        console.error('Error fetching headline:', error);
        setError(true); // Set error to true if there's an error fetching headline data
      }
    };
    fetchHeadline();
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_headline/${id}`, formData);
      console.log('Headline updated successfully:', response.data);
      alert(response.data.message);
      navigate('/adminheadline', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error updating headline:', error.response.data);
      alert('Sorry, an error occurred while updating the headline.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />           <h1 className="h1stake">Edit Headline</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <select
                name="Type"
                value={formData.Type}
                onChange={handleChange}
                required
              >
                <option value="">Select Type</option>
                <option value="News">News</option>
                <option value="DevelopmentUpdate">Development Update</option>
              </select>
              <label>Title:</label>
              <input
                type="text"
                name="Title"
                value={formData.Title}
                onChange={handleChange}
                placeholder="Headline Title"
                required
              />
              <label>Detail:</label>
              <textarea
                name="HeadlineDetail"
                value={formData.HeadlineDetail}
                onChange={handleChange}
                placeholder="Headline Detail"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditHeadline;
