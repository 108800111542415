import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/AdminHeader';
import Sidebar from '../components/AdminSidebar';
import axios from 'axios';

const AdminList = () => {
    const location = useLocation();
    const { email , userRole, userPermissions} = location.state || {};
    const [error, setError] = useState(false);
    const [contactData, setContactData] = useState([]);

    const navigate = useNavigate();
  
    useEffect(() => {
      if (!email) {
        setError(true); // Set error to true if email is not provided
      } 
       fetchData();
    }, [email]);

const fetchData = async () => {
  try {
    const response = await axios.get('https://d1server.d1capitalparkcity.com/fetchalldata');
    if (response.data.error) {
      alert(response.data.error);
    } else {
      setContactData(response.data);
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      alert(error.response.data.error);
    } else {
      alert("Sorry for the inconvenience! Technical issues occurred. Please try again later!");
      console.log(error);
    }
  }
};


    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Contact?');
        if (!confirmDelete) {
            return; // If user cancels, do nothing
        }
    
        try {
            await axios.delete(`https://d1server.d1capitalparkcity.com/deleteadmin/${id}`);
            // If deletion is successful, fetch updated data
            fetchData();
        } catch (error) {
            console.error('Error deleting contact submission:', error);
            // Handle error
        }
    };
  
      const handleEdit = (id) => {
        navigate('/editcontactus', { state: { id, email } });
    };


    const getRoleString = (role) => {
        switch(role) {
            case 1:
                return 'Super Admin';
            case 2:
                return 'Admin';
            case 3:
                return 'Moderator';
            case 4:
                return 'Content Editor';
            case 5:
                return 'Customer Support';
            default:
                return 'Not Assigned';
        }
    };
    

    return (
        <div className='AdminNews-Container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent' style={{ display: 'flex', maxWidth: '1200px', margin: '20px auto' }}>
                    <div className='AdminNews-Sidebar' style={{ flex: '1' }}>
                     <Sidebar 
                       email={email || ''} 
                       userRole={userRole || ''} 
                       userPermissions={userPermissions || []} 
                     />                      </div>
                    <div className='AdminNews-Main' style={{ flex: '3', paddingLeft: '20px' }}>
                        <h1 className="h1stake">Admin Details</h1>
                        <table className="table table-striped" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Name</th>
                                    <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Email</th>
                                    <th style={{ border: '1px solid black', padding: '8px', width: '40%' }}>Phone Number</th>
                                    <th style={{ border: '1px solid black', padding: '8px', width: '40%' }}>Role</th>
                                    <th style={{ border: '1px solid black', padding: '8px', width: '20%' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactData.map(contact => (
                                    <tr key={contact._id}>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{contact.name}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{contact.email}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{contact.pnumber}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{getRoleString(contact.role)}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>
                                        <div style={{padding: '8px'}}>
                                              
                                                <td>
                                                <button className="btn btn-danger" onClick={() => handleDelete(contact._id)}>Delete</button>
                                                </td>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminList;
