// EditContactus.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Import CSS for styling

const EditContactus = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, email, userRole, userPermissions } = location.state || {};
    const [contactData, setContactData] = useState({});
    const [newData, setNewData] = useState({
        Name: '',
        Email: '',
        PhoneNumber: '',
        Message: '',
        Reamarks: '',
        Contactedby: ''
    });
    const [error, setError] = useState(false); // State for error handling
    const [success, setSuccess] = useState(false); // State for success feedback

    // Fetch contact data using the provided ID
    useEffect(() => {
        if (!email) {
            setError(true);
        }
        fetchData();
    }, [email]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_contact_submission/${id}`);
            if (response.status === 200) {
                const data = response.data;
                setContactData(data);
                // Set initial form values based on fetched data
                setNewData({
                    Name: data.Name,
                    Email: data.Email,
                    PhoneNumber: data.PhoneNumber,
                    Message: data.Message,
                    Reamarks: data.Reamarks,
                    Contactedby: data.Contactedby
                });
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(true); // Set error state if fetching data fails
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://d1server.d1capitalparkcity.com/update_contact_submission/${id}`, newData);
           alert('Contact Detail Updated!');
                navigate('/admincontacusdata', { state: { email, userRole, userPermissions } });
        } catch (error) {
            console.error('Error updating contact submission:', error);
            setError(true); // Set error state if updating fails
        }
    };

    return (
        <div className='AddNews-Container'>
            <Header />
            {error ? (
                <h1>Error 404: Page Not Found</h1>
            ) : (
                <div>
                    <div>
                    <Sidebar 
                     email={email || ''} 
                     userRole={userRole || ''} 
                     userPermissions={userPermissions || []} 
                   /> 
                    </div>
                    <h1 className="h1stake">Edit Contact Us</h1>
                    <div className='AddNews-Form'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Name:</label>
                                <input type="text" className="form-control" name="Name" value={newData.Name} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>Email:</label>
                                <input type="text" className="form-control" name="Email" value={newData.Email} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>Phone Number:</label>
                                <input type="text" className="form-control" name="PhoneNumber" value={newData.PhoneNumber} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>Message:</label>
                                <textarea className="form-control" name="Message" value={newData.Message} onChange={handleInputChange}></textarea>
                            </div>
                            <div className="form-group">
                                <label>Remarks:</label>
                                <input type="text" className="form-control" name="Reamarks" value={newData.Reamarks} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label>Contacted By:</label>
                                <input type="text" className="form-control" name="Contactedby" value={newData.Contactedby} onChange={handleInputChange} />
                            </div>
                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>
                    </div>
                </div>
            )}
            {/* Success Feedback */}
            {success && <p>Contact information updated successfully. Redirecting...</p>}
        </div>
    );
};

export default EditContactus;
