import React, { useState, lazy, Suspense } from 'react';
import Header from '../../components/Header';
import phata from '../../assets/phata.gif';
import ReactLoading from '../../assets/React-Loading.gif';
import FloatingWhatsapp from '../../components/FloatingWhatsapp';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { IKImage } from 'imagekitio-react';
import PhoneInput from 'react-phone-input-2'; 
import 'react-phone-input-2/lib/style.css'; 
import "../../styles/WebPortalUser/Login.css";
import  SignupDetail from '../../components/WebPortaLoginSignup/SignupDetail.js'


const Footer = lazy(() => import('../../components/Footer'));

const Login = () => {
  // State variables to store form data
  const [formData, setFormData] = useState({
    cnic: '',
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you can perform registration logic
    console.log('Form Data:', formData);
    // Reset the form fields after submission
    setFormData({
      cnic: '',
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: ''
    });
  };

  // Function to handle input changes and apply formatting
  const handleChange = (value, id) => {
    setFormData({ ...formData, [id]: value });
  };







 const formatCNICWithDashes = (input) => {
    // Remove dashes from the input value
    const strippedInput = input.replace(/-/g, '');
    // Add dashes after the 5th and 12th characters
    return strippedInput.replace(/(.{5})(.{7})(.)/, "$1-$2-$3");
  };

  // Function to remove dashes from CNIC or NICOP number
  const removeDashesFromCNIC = (input) => {
    // Remove dashes from the input value
    return input.replace(/-/g, '');
  };

  // Handle changes in the CNIC input field
const handleCNICChange = (e) => {
  // Remove non-numeric characters except dashes
  const sanitizedValue = e.target.value.replace(/[^\d-]/g, '');

  // Restrict the number of characters to match the CNIC format
  const formattedValue = sanitizedValue.substring(0, 15);

  // Format CNIC with dashes
  const formattedCNIC = formatCNICWithDashes(formattedValue);

  // Update the formData state correctly
  setFormData({ ...formData, cnic: formattedCNIC });
};









  return (
    <div className='Login-Container'>
      <Helmet>
        <title>D1 Capital Park City | User Web Portal</title>
        <meta name="description" content="D1 Capital Park City Group and Team." />
        <link rel='canonical' href='https://d1capitalparkcity.com/login' />
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor: "#000", width: "100%", height: "100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>

        <div className="Header">
          <Header />
          <div className="phata">
            <img src={phata} alt="Phata" loading="lazy" />
          </div>
        </div>
        <div>
          <IKImage
            path="/D1logo.png.png?updatedAt=1708775472098"
            publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
            urlEndpoint='https://ik.imagekit.io/HamzaMalik'
            className="logo-login-image"
          />
          <h1 className='h1stake'>MEMBER WEB PORTAL</h1>
        </div>
        <div className='Login-Page-Detail-Container'>
        
        <div className='Login-Page'>
          <form onSubmit={handleSubmit}>
            <h2>Sign Up</h2>
            <h5>Already Have an Account? <span style={{ color: 'blue', cursor: 'pointer' }}> <Link to={'/login'}>Sign In</Link></span></h5>


            <div>
              <label htmlFor='cnic'>CNIC:</label>
              <input
                type='text'
                id='cnic'
                value={formData.cnic}
                onChange={handleCNICChange}
                onBlur={(e) => setFormData(formatCNICWithDashes(e.target.value))}
                onFocus={(e) => setFormData(removeDashesFromCNIC(e.target.value))}
                required
              />
            </div>
            <div>
              <label htmlFor='name'>Name:</label>
              <input
                type='text'
                id='name'
                value={formData.name}
                onChange={handleChange}
                maxLength={30}
                required
              />
            </div>
            <div>
              <label htmlFor='email'>Email:</label>
              <input
                type='email'
                id='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor='phoneNumber'>Phone Number:</label>
              <PhoneInput
                inputProps={{
                  id: 'phoneNumber',
                  required: true
                }}
                country={'pk'}
                value={formData.phoneNumber}
                onChange={(value) => setFormData({ ...formData, phoneNumber: value })}
              />
            </div>

            <div>
              <label htmlFor='password'>Password:</label>
              <input
                type='password'
                id='password'
                value={formData.password}
                onChange={handleChange}
                maxLength={16}
                minLength={8}
                required
              />
            </div>
            <div>
              <label htmlFor='confirmPassword'>Confirm Password:</label>
              <input
                type='password'
                id='confirmPassword'
                value={formData.confirmPassword}
                onChange={handleChange}
                maxLength={16}
                minLength={8}
                required
              />
            </div>
            <button className='btn42-43 btn-42' type='submit'>Register</button>
          </form>
        </div>
        <div><SignupDetail/></div>
</div>

        <FloatingWhatsapp />

        <br />
        <div>
          <Footer />
        </div>
      </Suspense>
    </div>
  )
}

export default Login;
