// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './screen/Homepage.js';
import AboutUsPage from './screen/Aboutus.js';
import ContactUsPage from './screen/Contactus.js';
import  PricingPage from './screen/Pricing.js';
import MediaPage from './screen/Media.js';
import Projectlocation from './screen/Projectlocation.js';
import Gallery from './screen/Gallery.js';
import DevelopmentUpdates from './screen/DevelopmentUpdates.js';
import FAQs from './screen/FAQS.js';
import FileVerification from './screen/FileVerification.js';
import NewsEvents from './screen/NewsEvents.js';
import PayOnline from './screen/PayOnline.js';
import NewseventDetail from './screen/NewseventDetail.js';
import AuthorizesSalesPartner from './screen/AuthorizesSalesPartner.js';
import BookOnline from './screen/BookOnline.js';

import Signup from "./screen/AdminPannel/Signup.js";
import Login from "./screen/AdminPannel/Login.js";
import ErrorPage403 from './screen/AdminPannel/Error403.js';
import Dashboard from "./screen/AdminPannel/Pannel/Dashboard.js";
import AdminNews from "./screen/AdminPannel/components/AdminNewsEvent/AdminNews.js";
import AddNews from "./screen/AdminPannel/components/AdminNewsEvent/AddNews.js";
import EditNews from "./screen/AdminPannel/components/AdminNewsEvent/EditNews.js";
import AdminAboutus from "./screen/AdminPannel/components/AboutUs/AdminAboutus.js";
import AddNewMember from "./screen/AdminPannel/components/AboutUs/AddNewMemebr.js";
import EditMember from './screen/AdminPannel/components/AboutUs/EditMember.js';
import AdminDevelopment from "./screen/AdminPannel/components/DevelopmentUpdates/AdminDevelopment.js";
import AddDevelopmentUpdate from './screen/AdminPannel/components/DevelopmentUpdates/AddDevelopmentUpdate.js';
import EditDevelopmentUpdate from './screen/AdminPannel/components/DevelopmentUpdates/EditDevelopmentUpdate.js';
import AddPricePlan from "./screen/AdminPannel/components/Pricing/AddPricePlan.js";
import AdminPricing from "./screen/AdminPannel/components/Pricing/AdminPricing.js";
import EditPricePlan from "./screen/AdminPannel/components/Pricing/EditPricePlan.js";
import AdminSalesPartner from './screen/AdminPannel/components/SalesPartner/AdminSalesPartner.js';
import AddSalesPartner from './screen/AdminPannel/components/SalesPartner/AddSalesPartner.js';
import EditSalesPartner from './screen/AdminPannel/components/SalesPartner/EditSalesPartner.js';
import AdminOurGroup from './screen/AdminPannel/components/AboutUs/OurGroup/AdminOurGroup.js';
import AdminPopup from './screen/AdminPannel/components/PopUps/AdminPopup.js';
import AddOurGroup from './screen/AdminPannel/components/AboutUs/OurGroup/AddOurGroup.js';
import EditOurGroup from './screen/AdminPannel/components/AboutUs/OurGroup/EditOurGroup.js';
import AddPopup from './screen/AdminPannel/components/PopUps/AddPopup.js';
import EditPopup from './screen/AdminPannel/components/PopUps/EditPopup.js';
import AdminContactusdata from './screen/AdminPannel/components/ContactUsData/AdminContactusdata.js';
import EditContactus from './screen/AdminPannel/components/ContactUsData/EditContactus.js';
import AdminHeadlines from './screen/AdminPannel/components/Headlines/AdminHeadlines.js';
import AddNewHeadline from './screen/AdminPannel/components/Headlines/AddNewHeadline.js';
import EditHeadline from './screen/AdminPannel/components/Headlines/EditHeadline.js';
import AddAdmin from './screen/AdminPannel/AdminManagement/AddAdmin.js';
import AdminFaqs from './screen/AdminPannel/components/FAQS/AdminFaqs.js';
import AddFaqs from './screen/AdminPannel/components/FAQS/AddFaqs.js';
import EditFaqs from './screen/AdminPannel/components/FAQS/EditFaqs.js';
import AdminSocialMedia from './screen/AdminPannel/components/SocialMedia/AdminSocialMedia.js';
import AddSocialMedia from './screen/AdminPannel/components/SocialMedia/AddSocialMedia.js';
import EditSocialMedia from './screen/AdminPannel/components/SocialMedia/EditSocialMedia.js';
import AdminGallery from './screen/AdminPannel/components/Gallery/AdminGallery.js';
import AddGallery from './screen/AdminPannel/components/Gallery/AddGallery.js';
import EditGallery from './screen/AdminPannel/components/Gallery/EditGallery.js';
import AdminContactDetail from './screen/AdminPannel/components/ContactUsData/ContactDetailAdmin.js';
import ContactDetailEdit from './screen/AdminPannel/components/ContactUsData/ContactDetailEdit.js';
import FooterParaAdmin from './screen/AdminPannel/components/Footer/AdminFooterPara.js';
import FooterParaEdit from './screen/AdminPannel/components/Footer/FooterParaEdit.js';
import AdminFooterLinks from './screen/AdminPannel/components/Footer/AdminFooterLink.js';
import FooterLinkEdit from './screen/AdminPannel/components/Footer/FooterLinkEdit.js';

import Profile from './screen/AdminPannel/Pannel/Profile.js';
import EditProfile from './screen/AdminPannel/Pannel/EditProfile.js';
import ChangePassword from './screen/AdminPannel/Pannel/ChangePassword.js';
import AdminList from './screen/AdminPannel/AdminManagement/AdminList.js';
import ProjectAmenitiesAdmin from './screen/AdminPannel/components/ProjectAmenities/ProjectAmenitiesAdmin.js';
import EditProjectAmenities from './screen/AdminPannel/components/ProjectAmenities/EditProjectAmenities.js';
import WonderLocationAdmin from './screen/AdminPannel/components/WonderLocation/WonderLocationAdmin.js';
import AddEditWonderLocation from './screen/AdminPannel/components/WonderLocation/AddEditWonderLocation.js';
import AdminLocationMap from './screen/AdminPannel/components/UnderstandingLocation/AdminLocationMap.js';
import AddEditLocationMap from './screen/AdminPannel/components/UnderstandingLocation/AddEditLocationMap.js';
import RolePermissions from './screen/AdminPannel/components/RolePermissions/RolePermission.js';
import ContentDashboard from './screen/AdminPannel/Pannel/ContentDashboard.js';
import CustomerDashboard from './screen/AdminPannel/Pannel/CustomerDashboard.js';
import AdminPassword from './screen/AdminPannel/AdminManagement/AdminPassword.js';



function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes>
        <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUsPage />} /> 
          <Route path="/deveolpmentupdates" element={<DevelopmentUpdates />} />
          <Route path="/FAQs" element={<FAQs/>} />
          <Route path="/fileverification" element={<FileVerification/>} />
          <Route path="/newsevent" element={<NewsEvents />} />
          <Route path='/authorizedpartner' element={<AuthorizesSalesPartner/>}/>
          <Route path="/news/:id" element={<NewseventDetail/>}/>
          <Route path="/bookonline" element={<BookOnline />} />     
          <Route path="/payonline" element={<PayOnline />} />      
          <Route path="/location" element={<Projectlocation/>}/>         
          <Route path="/contact-us" element={<ContactUsPage />} /> 
          <Route path="/pricing" element={<PricingPage />} /> 
          <Route path="/media" element={<MediaPage />} /> 
          <Route path="/gallery" element={<Gallery />} /> 

          <Route path="/d11426" element={<Login />} /> 
          <Route path='/error403' element={<ErrorPage403/>}/>
          <Route path="/dashboard" element={<Dashboard />} /> 
          <Route path="/adminnews" element={<AdminNews />} /> 
          <Route path="/addnews" element={<AddNews />} />
          <Route path="/editnews" element={<EditNews />} /> 
          <Route path="/adminaboutus" element={<AdminAboutus/>}/> 
          <Route path="/addnewmember" element={<AddNewMember/>}/>
          <Route path="/editmember" element={<EditMember/>}/>
          <Route path="/admindevelopment" element={<AdminDevelopment/>}/> 
          <Route path="/adddevelopmentupdate" element={<AddDevelopmentUpdate/>}/> 
          <Route path="/editdevelopmentupdate" element={<EditDevelopmentUpdate/>}/>
          <Route path="/adminpricing" element={<AdminPricing/>}/> 
          <Route path="/addpriceplan" element={<AddPricePlan/>}/> 
          <Route path="/editpriceplan" element={<EditPricePlan/>}/> 
          <Route path="/adminsalespartner" element={<AdminSalesPartner/>}/> 
          <Route path="/addsalespartner" element={<AddSalesPartner/>}/> 
          <Route path="/editsalespartner" element={<EditSalesPartner/>}/>
          <Route path='/adminourgroup' element={<AdminOurGroup/>}/>
          <Route path='/addourgroup' element={<AddOurGroup/>}/>
          <Route path='/editourgroup' element={<EditOurGroup/>}/>
          <Route path='/adminpopup' element={<AdminPopup/>}/>
          <Route path='/addpopup' element={<AddPopup/>}/>
          <Route path='/editpopup' element={<EditPopup/>}/>
          <Route path='/admincontacusdata' element={<AdminContactusdata/>}/>
          <Route path='/editcontactus' element={<EditContactus/>}/>
          <Route path='/adminheadline' element={<AdminHeadlines/>}/>
          <Route path='/addnewheadline' element={<AddNewHeadline/>}/>
          <Route path='/editheadline' element={<EditHeadline/>}/>
          <Route path='/adminfaqs' element={<AdminFaqs/>}/>
          <Route path='/addfaqs' element={<AddFaqs/>}/>
          <Route path='/editfaqs' element={<EditFaqs/>}/>
          <Route path='/adminsocialmedia' element={<AdminSocialMedia/>}/>
          <Route path='/addsocialmedia' element={<AddSocialMedia/>}/>
          <Route path='/editsocialmedia' element={<EditSocialMedia/>}/>
          <Route path='/admingallery' element={<AdminGallery/>}/>
          <Route path='/addgallery' element={<AddGallery/>}/>
          <Route path='/editgallery' element={<EditGallery/>}/>
          <Route path='/admincontactdetail' element={<AdminContactDetail/>}/>
          <Route path='/editcontactdetail' element={<ContactDetailEdit/>}/>
          <Route path='/adminfooterpara' element={<FooterParaAdmin/>}/>
          <Route path='/editfooterpara' element={<FooterParaEdit/>}/>
          <Route path='/adminfooterlink' element={<AdminFooterLinks/>}/>
          <Route path='/editfooterlink' element={<FooterLinkEdit/>}/>
          <Route path='/projectamenitiesadmin' element={<ProjectAmenitiesAdmin/>}/>
          <Route path='/addeditprojectamenities' element={<EditProjectAmenities/>}/>
          <Route path='/adminwonderlocation' element={<WonderLocationAdmin/>}/>
          <Route path='/addeditwonderlocation' element={<AddEditWonderLocation/>}/>
          <Route path='/adminunderstandinglocation' element={<AdminLocationMap/>}/>
          <Route path='/addeditunderstandinglocation' element={<AddEditLocationMap/>}/>
          <Route path="/signup" element={<Signup />} /> 
          <Route path='/contentdashboard' element={<ContentDashboard/>}/>
          <Route path='/customerdashboard' element={<CustomerDashboard/>}/>

          <Route path='/profile' element={<Profile/>}/>
          <Route path='/rolepermission' element={<RolePermissions/>}/>
          <Route path='/edit-profile' element={<EditProfile/>}/>
          <Route path='/changepassword' element={<ChangePassword/>}/>
          <Route path='/adminlist' element={<AdminList/>}/>
          <Route path='/addadmin' element={<AddAdmin/>}/>
          <Route path='/adminpassword' element={<AdminPassword/>}/>


        </Routes>
      </div>
    </Router>
  );
}

export default App;
