import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../AdminHeader';
import Sidebar from '../../AdminSidebar'; // Import Sidebar if needed

const AdminOurGroup = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [ourGroup, setOurGroup] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        if (!email) {
          setError(true); // Set error to true if email is not provided
          return;
        } 

        const fetchData = async () => {
          try {
            const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_ourgroup');
            if (!response.ok) {
              throw new Error('Sorry, a technical issue occurred while fetching price plans.');
            }
            const data = await response.json();
            setOurGroup(data.reverse());
          } catch (error) {
            console.error('Error fetching price plans:', error);
            setError(true);
          }
        };
    
        fetchData();

    }, [email]);


    const handleRemoveArticle = async (id) => {
      // Ask for confirmation before deleting the article
      const confirmDelete = window.confirm('Are you sure you want to delete this article?');
      if (!confirmDelete) {
          return; // If user cancels, do nothing
      }
  
      try {
          // Send DELETE request to delete the article
          await fetch(`https://d1server.d1capitalparkcity.com/delete_ourgroup/${id}`, {
              method: 'DELETE',
          });
  
          // After successful removal, fetch and update the news events
          const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_ourgroup');
          const data = await response.json();
          setOurGroup(data.reverse());
      } catch (error) {
          console.error('Error removing article:', error);
      }
  };

    const handleAddNewMember = () => {
      navigate('/addourgroup', { state: { email, userRole, userPermissions } });
    };

    const handleEditNews = (id) => {
      navigate('/editourgroup', { state: { id, email, userRole, userPermissions } });
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
          <h1 className="h1stake">Our Group</h1>
          <p style={{ color: 'red' }}><b>Note: Image Should be uploaded at Image kit and size should be (500x500)</b></p>

          <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddNewMember}>Add New Member</button>
            </div>
            <div className="adminnews-events-grid">
                            {ourGroup.map((group, index) => (
                                <div key={index} className="news-event-card">
                                    <img src={group.ProfileImage} alt={group.Title} className="news-event-image" loading='lazy' />
                                    <h2 className="news-event-heading">{group.Type}</h2>
                                    <h2 className="news-event-heading">{group.Name}</h2>
                                    <div className='News-Buttons'>
                                    <button className="btn42-43 btn-42" onClick={() => handleEditNews(group._id)}>Edit</button>
                                    <button className="btn42-43 btn-42" onClick={() => handleRemoveArticle(group._id)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminOurGroup