import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../AdminHeader';
import Sidebar from '../../AdminSidebar';
import '../../../PannelStyling/AddNews.css'; // Reuse styling from AddNews.css

const EditOurGroup = () => {
  const location = useLocation();
  const { email, id , userRole, userPermissions} = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    Type: '',
    Title: '',
    Name: '',
    Message: '',
    ProfileImage: '',
    Experience:'',
    index:''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_ourgroup/${id}`);
        const { Type, Title, Name, Message, ProfileImage, Experience, index } = response.data;
        setFormData({ Type, Title, Name, Message, ProfileImage, Experience, index });
      } catch (error) {
        console.error('Error fetching group:', error);
        setError(true);
      }
    };

    if (!email) {
      setError(true);
    } else {
      fetchGroup();
    }
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_ourgroup/${id}`, formData);
      console.log('Group updated successfully:', response.data);
      alert(response.data.message);
      navigate('/adminourgroup', { state: { email, userRole, userPermissions } });
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Edit Our Group</h1>
            <div className='AddNews-Form'>
              <form onSubmit={handleSubmit}>
                <label>Type:</label>
                <select
                  name="Type"
                  value={formData.Type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Chairman">Chairman</option>
                  <option value="CEO">CEO</option>
                  <option value="President">President</option>
                  <option value="Director">Director</option>
                </select>
                <label>Title:</label>
                <input
                  type="text"
                  name="Title"
                  value={formData.Title}
                  onChange={handleChange}
                  placeholder="Title"
                  required
                />
                <label>Name:</label>
                <input
                  type="text"
                  name="Name"
                  value={formData.Name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
                <label>Message:</label>
                <input
                  type="text"
                  name="Message"
                  value={formData.Message}
                  onChange={handleChange}
                  placeholder="Message"
                  required
                />
                <label>Profile Image:</label>
                <input
                  type="text"
                  name="ProfileImage"
                  value={formData.ProfileImage}
                  onChange={handleChange}
                  placeholder="Profile Image"
                  required
                />
                <label>Experience:</label>
              <input
                type="text"
                name="Experience"
                value={formData.Experience}
                onChange={handleChange}
                placeholder="Experience"
                required
              />
                <label>Index:</label>
              <input
                type="text"
                name="index"
                value={formData.index}
                onChange={handleChange}
                placeholder="Index"
                required
              />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditOurGroup;
