import React, { Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Header from '../components/Header';
import Popup from '../components/Homepage/Popup';
import MainHomePopup from '../components/Homepage/MainHomePopup';
import '../styles/Homepage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import specialoffer from '../assets/specialoffer.png';
import preloader from '../assets/preloader.gif';
import phata from '../assets/phata.gif';
import { IKImage } from 'imagekitio-react';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';
import axios from 'axios';

// Lazy load Smartfacilities and FloatingWhatsapp components
const LazySmartfacilities = React.lazy(() => import('../components/Homepage/Smartfacilities'));
const LazyFloatingWhatsapp = React.lazy(() => import('../components/FloatingWhatsapp'));

const Homepage = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [popupDataAvailable, setPopupDataAvailable] = useState(false); // State to indicate if popup data is available


  useEffect(() => {
    const homepageLoaded = sessionStorage.getItem('homepageLoaded');

    if (!homepageLoaded) {
      // Homepage is being loaded for the first time
      const timeout = setTimeout(() => {
        setLoading(false);
        sessionStorage.setItem('homepageLoaded', 'true');
        incrementVisitorCount(); // Call visitor increment API on session start

      }, 7240);

      return () => clearTimeout(timeout);
    } else {
      // Homepage has been loaded before
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Fetch popup data from the API
    const fetchPopupData = async () => {
      try {
        const response = await axios.get('http://d1server.d1capitalparkcity.com/fetch_popups?type=MainHome');
        if (response.data.length > 0) {
          setPopupDataAvailable(true); // Set to true if data is available
        } else {
          setPopupDataAvailable(false); // Set to false if no data is available
        }
      } catch (error) {
        console.error('Error fetching pop-ups:', error);
       // alert('Technical Issue Occurred While Fetching Pop data.');
      }
    };

    fetchPopupData();
  }, []);



  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const incrementVisitorCount = async () => {
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/visitorincrement');
      console.log('Visitor count incremented:', response.data);
    } catch (error) {
      console.error('Error incrementing visitor count:', error);
    }
  };


  return (
    <div className="homepage-container">
      <Helmet>
        <title>D1 Capital Park City | Home</title>
        <meta name="description" content="Discover luxury living near Islamabad Airport at D1 Capital Park City. Modern homes, serene community, prime location."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/'/>
      </Helmet>
      {loading ? (
        <div className="loader-container">
          <img src={preloader} alt="Loading...." />
        </div>
      ) : (
        <Suspense fallback={<div style={{ backgroundColor: "#000", width: "100%", height: "100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
          <Header />
          <div className="Header">
            <div className='specialoffer'>
              <Link to="/pricing">
                <img src={specialoffer} alt='special offer' />
              </Link>
            </div>
            <div className="phata">
              <img src={phata} alt="Phata" />
            </div>
          </div>
          <div className="slider-container">
            <Slider {...sliderSettings}>
              <div>
                <IKImage
                  path="/Slider1.png?updatedAt=1708761318511"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
              <div>
                <IKImage
                  path="/Slider2.png?updatedAt=1708761318510"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
              <div>
                <IKImage
                  path="/Slider3.png?updatedAt=1708761318604"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
              <div>
                <IKImage
                  path="/Slider4.png?updatedAt=1708761318495"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
            </Slider>
          </div>
          <div className="smartservices">
            <LazySmartfacilities />
          </div>
          <div className='MainHome-Popup'>
          {popupDataAvailable && showPopup && <MainHomePopup onClose={handleClosePopup} />} {/* Conditionally render MainHomePopup if data is available */}
          </div>
          {showPopup && <Popup onClose={handleClosePopup} />}
          <LazyFloatingWhatsapp />
        </Suspense>
      )}
    </div>
  );
};

export default Homepage;
