import React, { useEffect, useState, Suspense } from 'react';
import Header from '../components/Header';
import phata from '../assets/phata.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import Footer from '../components/Footer';
import ReactPlayer from 'react-player';
import ReactLoading from '../assets/React-Loading.gif';
import DevelopmentPopUp from '../components/Development/DevelopmentPopUp.js';
import '../styles/Developmentupdates.css';
import axios from 'axios';
import {Helmet} from 'react-helmet-async';

const DevelopmentUpdates = () => {

  const [developmentUpdates, setDevelopmentUpdates] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const [popupDataAvailable, setPopupDataAvailable] = useState(false);
  const [headlines, setHeadlines] = useState([]);


  useEffect(() => {
   
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_development_updates');
        setDevelopmentUpdates(response.data.reverse()); // Assuming the response.data is an array of development updates
      } catch (error) {
        console.error('Error fetching development updates:', error);
       alert(error);
      }
    };
  
    fetchData();
  }, );


  useEffect(() => {
    // Fetch popup data from the API
    const fetchPopupData = async () => {
      try {
        const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_popups?type=DevelopmentUpdate');
        if (response.data.length > 0) {
          setPopupDataAvailable(true); // Set to true if data is available
        } else {
          setPopupDataAvailable(false); // Set to false if no data is available
        }
      } catch (error) {
        console.error('Error fetching pop-ups:', error);
       // alert('Technical Issue Occurred While Fetching Pop data.');
      }
    };

    fetchPopupData();
  }, []);

  useEffect(() => {
    const fetchHeadlines = async () => {
      try {
        const response = await fetch('https://d1server.d1capitalparkcity.com/fetch_headlines?Type=DevelopmentUpdate');
        const headlinedata = await response.json();
        setHeadlines(headlinedata.reverse()); 
      } catch (error) {
        console.error('Error fetching headlines:', error);
      }
    };

    fetchHeadlines();
  }, []);


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const text = document.querySelector('.development-text');
    if (text) {
      const words = text.innerText.split(' ');
  
      // Reset the inner text to empty
      text.innerText = '';
  
      // Iterate over each word and append it with a delay
      words.forEach((word, index) => {
        const span = document.createElement('span');
        span.innerText = word + ' ';
        span.style.opacity = 0;
        span.style.animation = `fadeIn 1s ${index * 0.2}s forwards`;
        text.appendChild(span);
      });
  
      // Define fadeIn animation
      const style = document.createElement('style');
      style.innerHTML = `
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `;
      document.head.appendChild(style);
  
      // Cleanup function to remove animation styles when component unmounts
      return () => {
        document.head.removeChild(style);
      };
    }
  }, []); // Run this effect only once after the component mounts
  

  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | Development Updates</title>
        <meta name="description" content="D1 Capital Park City Latest Development Updates."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/deveolpmentupdates'/>
      </Helmet>
    <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>

      <div>
        <h1 className='h1stake'>DEVELOPMENT UPDATES</h1>
      </div>
      
      
      <div className="headline-container">
        {headlines.map((headline, index) => (
          <div key={index} className="right-to-left-headline">
            <span className="development-red-text">{headline.Title}:</span>
            <span className="development-text">
              {headline.HeadlineDetail}
            </span>
          </div>
        ))}
      </div>
      <br/>



<div className='development-new-container'>
      <div className="development-updates-container">
        {/* Map over developmentUpdates to render each video */}
        {developmentUpdates
        .filter(update => update.type === 'video')
         .map(update => (
          <div key={update.id} className="development-update-card">
            <ReactPlayer
        url={update.videoSrc}
        controls={true}
        width="100%"
        height="auto"
      />
            <h2 className="development-card-heading">{update.heading}</h2>
            <p className="development-card-video-details">{update.details}</p>
          </div>
        ))}
      </div>







      <div className="development-updates-container">
      {developmentUpdates
       .filter(update => update.type === 'image')
      .map(update => (
        <div key={update.id} className="development-update-card">
          <img src={update.image} alt={update.heading} className="development-card-image" loading='lazy'/>
          <h2 className="development-card-heading">{update.heading}</h2>
          <p className="development-card-details">{update.details}</p>
        </div>
      ))}
    </div>


    {popupDataAvailable && showPopup && <DevelopmentPopUp onClose={handleClosePopup} />}
    </div>



<Whatsapp/>

      <Footer />
      </Suspense>
    </div>
  );
};

export default DevelopmentUpdates;
