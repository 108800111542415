import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader.js';
import Sidebar from '../AdminSidebar.js';
import axios from 'axios';
import '../../PannelStyling/AddNews.css'; // Adjust CSS file path as needed

const AddGallery = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    link: '',
    title: '',
    description: '',
    index:'',
  });

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]); // Fetch data when email changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_gallery', formData);
      console.log('Gallery item added successfully:', response.data);
      alert(response.data.message);
      navigate('/admingallery', { state: { email, userRole, userPermissions } });
      // Redirecting to AdminGallery page
      // window.location.href = '/admin-gallery';
    } catch (error) {
      console.error('Error adding Gallery item:', error.response.data);
      alert('Sorry, an error occurred while adding the gallery item.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />           <h1 className="h1stake">Add New Gallery Item</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Link:</label>
              <input
                type="text"
                name="link"
                value={formData.link}
                onChange={handleChange}
                placeholder="Image URL"
                required
              />
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Image Title"
                required
              />
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Image Description"
                required
              />
               <label>Index:</label>
              <input
                type="number"
                name="index"
                value={formData.index}
                onChange={handleChange}
                placeholder="Index Number"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddGallery;
