import React, { useState, useEffect } from 'react';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';


const EditSalesPartner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, id, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    logo: '',
  });
 
  useEffect(() => {
    const fetchPartner = async () => {
      try {
        const response = await axios.get(`https://d1server.d1capitalparkcity.com/fetch_partner/${id}`);
        const { name, description, logo } = response.data;
        setFormData({ name, description, logo });
      } catch (error) {
        console.error('Error fetching partner:', error);
        setError(true);
      }
    };

    if (!email) {
      setError(true);
    } else {
      fetchPartner();
    }
  }, [email, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://d1server.d1capitalparkcity.com/update_partner/${id}`, formData);
      alert('Sales Partner updated successfully');
      navigate('/adminsalespartner', { state: { email, userRole, userPermissions } }); // Adjust the redirect route as necessary
    } catch (error) {
      console.error('Error updating partner:', error);
      setError(true);
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
          Error 404: Page Not Found
        </h1>
      ) : (
        <div >
         <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          
            <h1 className="h1stake">Edit Sales Partner</h1>
            <div className='AddNews-Form'>
            <form  onSubmit={handleSubmit}>
            <label>Logo URL:</label>
              <input
                type="text"
                name="logo"
                value={formData.logo}
                onChange={handleChange}
                placeholder="Logo URL"
                required
              />
              <label>Partner Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Partner Name"
                required
              />
              <label>Description:</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
                required
              />
             
              <button type="submit">Update Partner</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSalesPartner;
