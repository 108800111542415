import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner'; // Import loader spinner component
import '../../PannelStyling/AdminNews.css';
import '../../../../styles/Ourteams.css';

const AdminAboutus = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      setIsLoading(false); // Ensure loading stops when there's an error

    } else {
      // Fetch data when email is provided
      fetchMembersData(); // Fetch data
    }
  }, [email]); // Fetch data when email changes

  const fetchMembersData = async () => {
    try {
      // Make a request to your backend API to fetch data
      const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_aboutus');
      setMembers(response.data); // Update state with fetched data sorted by index
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true); // Set error to true if there's an error fetching data
    } finally {
      setIsLoading(false); // Set loading to false regardless of success or failure
    }
  };

  const handleRemoveMember = async (id) => {
    // Ask for confirmation before deleting the member
    const confirmDelete = window.confirm('Are you sure you want to delete this Member?');
    if (!confirmDelete) {
      return; // If user cancels, do nothing
    }

    try {
      // Send DELETE request to delete the member
      await axios.delete(`https://d1server.d1capitalparkcity.com/delete_aboutus/${id}`);

      // After successful removal, fetch and update the members
      fetchMembersData();
    } catch (error) {
      console.error('Error removing member:', error);
    }
  };

  const handleAddNewMember = () => {
    navigate('/addnewmember', { state: { email, userRole, userPermissions } });
  };

  const handleEditMember = (id) => {
    navigate('/editmember', { state: { id, email, userRole, userPermissions } });
  };

  return (
    <div className='AdminNews-Container'>
      <Header />
      {error ? (
        <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
      ) : (
        <div className='AdminContent'>
          <div className='AdminNews-Sidebar'>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          />          </div>
          <div className='AdminNews-Main'>
            <h1 className="h1stake">Our Team</h1>
            <p style={{ color: 'red' }}><b>Note: Image size should be (470x394)</b></p>
            <div className='AddNewEventButton'>
              <button className="btn42-43 btn-42" onClick={handleAddNewMember}>Add New Member</button>
            </div>
            {/* Render fetched members data */}
            <div className='adminnews-events-grid' >
              {isLoading ? (
                <div className="loading-overlay">
                  <Audio color="#FFD700" height={80} width={80} />
                  
                </div>
              ) : (
                members.map(member => (
                  <div className='Member1' key={member._id} style={{ border: '1px solid black', backgroundImage: 'none' }}>
                    <div className='Member-image'>
                      {/* Check if profile data is available */}
                      {member.profile && member.profile.data && (
                        <img src={`data:image/jpeg;base64,${member.profile.data}`} alt={member.name} loading='lazy' />
                      )}
                    </div>
                    <div className='Member-intro'>
                      <h1>{member.name}</h1>
                      <h2>{member.designation}</h2>
                      <h2>{member.index}</h2>
                      <div className='News-Buttons'>
                        <button className="btn42-43 btn-42" onClick={() => handleEditMember(member._id)}>Edit</button>
                        <button className="btn42-43 btn-42" onClick={() => handleRemoveMember(member._id)}>Delete</button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminAboutus;
