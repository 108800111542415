import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';

const AdminFooterPara = () => {
    const location = useLocation();
    const { email, userRole, userPermissions } = location.state || {};
    const [error, setError] = useState(false);
    const [footerParas, setFooterParas] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            setError(true);
            return;
        }
        // Fetch footer paras from the API
        const fetchFooterParas = async () => {
            try {
                const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_footer_paras');
                console.log(response.data);
                setFooterParas(response.data.reverse());
            } catch (error) {
                console.error('Error fetching footer paras:', error);
                setError(true); // Set error to true if there's an error fetching footer paras
            }
        };

        fetchFooterParas();

    }, []);

    const handleRemoveFooterPara = async (id) => {
        // Ask for confirmation before deleting the footer para
        const confirmDelete = window.confirm('Are you sure you want to delete this footer paragraph?');
        if (!confirmDelete) {
            return; // If user cancels, do nothing
        }

        try {
            // Send DELETE request to delete the footer para
            await axios.delete(`https://d1server.d1capitalparkcity.com/delete_footer_para/${id}`);

            // After successful removal, fetch and update the footer paras
            const response = await axios.get('https://d1server.d1capitalparkcity.com/fetch_footer_paras');
            setFooterParas(response.data.reverse());
        } catch (error) {
            console.error('Error removing footer para:', error);
        }
    };

    const handleEditFooterPara = (id) => {
        navigate('/editfooterpara', { state: { id, email, userRole, userPermissions } });
    };

    const handleAddFooterPara = () => {
        navigate('/editfooterpara', { state: { email, userRole, userPermissions } });
    };

    return (
        <div className='AdminNews-Container'>
            <Header />
            {error ? (
                <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
            ) : (
                <div className='AdminContent'>
                    <div className='AdminNews-Sidebar'>
                    <Sidebar 
                       email={email || ''} 
                       userRole={userRole || ''} 
                       userPermissions={userPermissions || []} 
                     /> 
                    </div>
                    <div className='AdminNews-Main'>
                        <h1 className="h1stake">Footer Paragraphs</h1>
                        <p style={{ color: 'red' }}><b>Note: Upload Image using Image kit, Size should be (300x300)</b></p>
                        <div className='AddNewEventButton'>
                            <button className="btn42-43 btn-42" onClick={handleAddFooterPara}>Add New Footer Paragraph</button>
                        </div>
                        <div className="adminnews-events-grid">
                            {footerParas.map((para, index) => (
                                <div key={index} className="news-event-card">
                                    <img src={para.logo} alt='D1'/>
                                    <div className="contact-details">
                                        <p style={{ whiteSpace: 'pre-line' }}>{para.para}</p>
                                    </div>
                                    <div className='News-Buttons'>
                                        <button className="btn42-43 btn-42" onClick={() => handleEditFooterPara(para._id)}>Edit</button>
                                        <button className="btn42-43 btn-42" onClick={() => handleRemoveFooterPara(para._id)}>Delete</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminFooterPara;
