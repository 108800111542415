import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../AdminHeader';
import Sidebar from '../AdminSidebar';
import axios from 'axios';
import '../../PannelStyling/AddNews.css';

const AddDevelopmentUpdate = () => {
  const location = useLocation();
  const { email, userRole, userPermissions } = location.state || {};
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    type: '',
    image: '',
    videoSrc: '',
    heading: '',
    details: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!email) {
      setError(true); // Set error to true if email is not provided
      return;
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://d1server.d1capitalparkcity.com/save_development_update', formData);
      console.log('Development update added successfully:', response.data);
      alert(response.data.message);
      // Redirect to the development updates page
      navigate('/admindevelopment', { state: { email , userRole, userPermissions} });
    } catch (error) {
      console.error('Error adding development update:', error.response.data);
      alert('Failed to add development update. Please try again.');
    }
  };

  return (
    <div className='AddNews-Container'>
      <Header />
      {error ? (
        <div>
          <h1 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>Error 404: Page Not Found</h1>
        </div>
      ) : (
        <div>
          <Sidebar 
            email={email || ''} 
            userRole={userRole || ''} 
            userPermissions={userPermissions || []} 
          /> 
          <h1 className="h1stake">Add New Development Update</h1>
          <div className='AddNews-Form'>
            <form onSubmit={handleSubmit}>
              <label>Type:</label>
              <select name="type" value={formData.type} onChange={handleChange} required>
                <option value="">Select type</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
              {formData.type === 'image' && (
                <>
                  <label>Image URL:</label>
                  <input
                    type="text"
                    name="image"
                    value={formData.image}
                    onChange={handleChange}
                    placeholder="Image URL"
                    required
                  />
                </>
              )}
              {formData.type === 'video' && (
                <>
                  <label>Video URL:</label>
                  <input
                    type="text"
                    name="videoSrc"
                    value={formData.videoSrc}
                    onChange={handleChange}
                    placeholder="Video URL"
                    required
                  />
                </>
              )}
              <label>Heading:</label>
              <input
                type="text"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
                placeholder="Heading"
                required
              />
              <label>Details:</label>
              <textarea
                name="details"
                value={formData.details}
                onChange={handleChange}
                placeholder="Details"
                required
              />
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddDevelopmentUpdate;
